import React from 'react';
import coin from './../resources/Images/3.png';
import wing from './../resources/Images/wing.png';
import armilary from './../resources/Images/1.png';
import cloud from './../resources/Images/cloud-yellow.png';
import scissor from './../resources/Images/5.png';
import SubstackWidget from './SubstackWidget';
import { sendMail } from '../functions/usefulFunctions';

const ManifestoContent = () => {
  return (
    <div className="max-w-[704px] mx-auto max-sm:px-[32px] sm:px-[10px] pt-[50px] pb-[120px] flex flex-col">
      <div>
        <h1 className="max-sm:text-[24px] uppercase tracking-wider sm:text-[32px] my-[16px] pb-3 font-bold">
          Owning the Future Together
        </h1>
        <h2 className="max-sm:text-[18px] space-mono sm:text-[22px] my-[16px] font-bold">
          OVERVIEW
        </h2>
        <div className="max-sm:text-[18px] sm:text-[22px] mb-[28px]">
          <p>
            At the core of the EID universe, or Eidverse, is a vision to
            redefine how we choose, fund, and celebrate progress on a global
            scale.
            <br />
            <br />
            In a nutshell, we own it - together. <br />
            <br /> Central to our approach is the use of blockchain technology
            because it enables authentic ownership, inclusive decision-making,
            borderless transfers of value, and transparency.
            <br />
            <br />
            Our foundational layer is the EID Protocol, a mission-driven set of
            rules and capabilities encoded using blockchain software. It
            establishes the framework for a global patronage and financial
            ecosystem, owned and steered exclusively by its participants.
          </p>
          <br />
          <h2 className="max-sm:text-[18px] uppercase space-mono sm:text-[22px] my-[16px] font-bold">
            Initial Ecosystem Components
          </h2>
        </div>
      </div>
      <div>
        <div className="mt-[24px]">
          <img src={wing} width="120px" height="120px" alt="" />
        </div>
        <h1 className="max-sm:text-[22px] sm:text-[30px] pt-[24px] pb-[5px] font-bold">
          Mission
        </h1>
        <p className="font-medium sm:text-[22px] max-sm:text-[18px]">
          Big, positive, and enduring outcomes
        </p>{' '}
        <br />
        <div className="max-sm:text-[16px] sm:text-[18px] mb-[28px] text-[#383838]">
          <p>
            The EID Protocol seeks to spark a new era of transformative impact,
            inspired by the Islamic Golden Age and other eras of profound human
            achievement.
          </p>
          <br />
          <p>
            Its aim is to bring together a diverse community, united in pursuit
            of significant, positive, and lasting change.
          </p>
          <br />
          <p>
            Through widespread ownership and participation, the protocol
            facilitates the growth of an ecosystem that nurtures and funds
            groundbreaking initiatives and exceptional individuals.
          </p>{' '}
          <br />
          <p>
            The sum of these shared efforts gives rise to the Eidverse, an
            umbrella term for the big, positive, and enduring outcomes enabled
            by the protocol’s owners.
          </p>
        </div>
      </div>
      <div>
        <div className="max-sm:mt-[24px] sm:mt-[40px]">
          <img src={coin} width="80px" height="90px" alt="" />
        </div>
        <h1 className="max-sm:text-[22px] sm:text-[30px] pt-[24px] pb-[5px] font-bold">
          EID Tokens
        </h1>
        <p className="font-medium sm:text-[22px] max-sm:text-[18px]">
          Units of utility and ownership
        </p>{' '}
        <br />
        <div className="max-sm:text-[16px] sm:text-[18px] mb-[28px] text-[#383838]">
          <p>
            The EID token functions as a dual instrument within the EID
            Protocol: it's a means of both support and funding, and a tangible
            symbol of shared ownership.
          </p>
          <br />
          <p>
            Holding and distributing these tokens is an act of empowering
            others, effectively transferring a stake in the future of the
            Eidverse.
          </p>
          <br />
          <p>
            This mechanism fosters a culture where support and contribution are
            intertwined, with each token serving as a vote towards nurturing
            projects and individuals aligned with the Eidverse’s mission.
          </p>{' '}
          <br />
          <p>
            The EID token is thus more than a digital asset; it's a tool for
            collective investment in a shared vision, driving collaborative
            progress and innovation.
          </p>
        </div>
      </div>
      <div>
        <div className="max-sm:mt-[24px] sm:mt-[40px]">
          <img src={armilary} width="75px" height="90px" alt="" />
        </div>
        <h1 className="max-sm:text-[22px] sm:text-[30px] pt-[24px] pb-[5px] font-bold">
          Shared, Dynamic Ownership
        </h1>
        <p className="font-medium sm:text-[22px] max-sm:text-[18px]">
          Core mechanism of the EID Protocol
        </p>{' '}
        <br />
        <div className="max-sm:text-[16px] sm:text-[18px] mb-[28px] text-[#383838]">
          <p>
            Shared, dynamic ownership is at the heart of the EID Protocol,
            embodying a unique concept where everyone owns a stake in the
            protocol, akin to shared land.
          </p>
          <br />
          <p>
            This ownership is not just a static investment; it's an active,
            evolving role in shaping the Eidverse. Owners jointly shoulder the
            responsibility of steering the protocol, ensuring its adherence to
            our collective mission.
          </p>
          <br />
          <p>
            Central to this concept is the idea of pooling funds and awarding
            tokens, critical in both backing meaningful projects and
            transferring ownership to individuals most aligned with our mission.
            This process, the pursuit of what we call marginal positive
            transfers, is what makes the ownership within the EID Protocol
            dynamic.
          </p>{' '}
          <br />
          <p>
            As the community grows, token distribution occurs primarily through
            structured mechanisms, with the treasury playing a key role in
            ensuring fair and mission-focused allocation.
          </p>{' '}
          <br />{' '}
          <p>
            Through this model, ownership in the Eidverse goes beyond mere
            possession; it’s a living, breathing part of the ecosystem that
            adapts and grows with its participants.
          </p>
        </div>
      </div>
      <div>
        <div className="max-sm:mt-[24px] sm:mt-[40px]">
          <img src={scissor} width="75px" height="90px" alt="" />
        </div>
        <h1 className="max-sm:text-[22px] sm:text-[30px] pt-[24px] pb-[5px] font-bold">
          The EID Foundation
        </h1>
        <p className="font-medium sm:text-[22px] max-sm:text-[18px]">
          Facilitating community-led progress
        </p>{' '}
        <br />
        <div className="max-sm:text-[16px] sm:text-[18px] mb-[28px] text-[#383838]">
          <p>
            The EID Foundation functions as a facilitator for the EID Protocol,
            ensuring that the collective will of the ecosystem's owners is
            effectively implemented.
          </p>
          <br />
          <p>
            It's not a governing body in the traditional sense but rather a
            platform that empowers community-led governance and decision-making.
          </p>{' '}
          <br />
          <p>
            Tasked with executing the decisions made by EID token holders, the
            foundation manages the day-to-day administrative and operational
            aspects, providing the necessary support to turn the community's
            vision into reality.
          </p>
          <br />
          <p>
            The foundation handles token distribution and treasury management
            with an eye towards fostering mission-aligned growth, ensuring that
            tokens are allocated in ways that amplify the community's impact.
          </p>{' '}
          <br />
          <p>
            It also provides the infrastructure necessary for token holders to
            engage in open, decentralized governance, making sure every voice is
            heard and every decision reflects the diverse perspectives within
            our community.
          </p>
        </div>
      </div>
      <div>
        <div className="max-sm:mt-[24px] sm:mt-[40px]">
          <img src={cloud} width="75px" height="90px" alt="" />
        </div>
        <h1 className="max-sm:text-[22px] sm:text-[30px] pt-[24px] pb-[5px] font-bold">
          The EID App
        </h1>
        <p className="font-medium sm:text-[22px] max-sm:text-[18px]">
          Simplified blockchain access and ecosystem engagement
        </p>{' '}
        <br />
        <div className="max-sm:text-[16px] sm:text-[18px] mb-[28px] text-[#383838]">
          <p>
            The EID App simplifies user interaction with blockchain technology.
            Integrating advanced features like account abstraction, it ensures
            that engaging with the Eidverse is straightforward, even for those
            new to blockchain.
          </p>
          <br />
          <p>
            This app acts as a secure wallet for EID tokens and enables
            essential functions like token awarding and pooling.
          </p>{' '}
          <br />
          <p>
            It's not just a tool but a gateway to active participation,
            empowering users to contribute to decision-making and support
            community-driven projects and initiatives.
          </p>{' '}
          <br />
          <p>
            Through this app, we make technology an enabler, not a barrier, for
            collective action and impact in the EID ecosystem.
          </p>
        </div>
      </div>
      <div className="max-sm:mt-[40px] sm:mt-[55px]">
        <h3 className="max-sm:text-[22px] sm:text-[24px] font-semibold uppercase">
          INTERESTED IN OWNING THIS?
        </h3>
        <p className="max-sm:text-[16px] sm:text-[18px] text-[#383838] max-sm:mt-[12px] sm:mt-[10px] sm:mb-[5px]">
          Thank you for taking the time to learn more about the growing EID
          ecosystem. Follow us on{' '}
          <a
            className="text-[#4267B2] hover:underline"
            href="https://twitter.com/EidverseFC"
            target="_blank"
          >
            X
          </a>{' '}
          and subscribe to our{' '}
          <a
            className="text-[#4267B2] hover:underline"
            href="https://eidverse.substack.com"
            target="_blank"
          >
            newsletter
          </a>{' '}
          to stay connected and informed, including about ownership
          opportunities. <br />
          <br />
          If you’re interested in coming aboard immediately as an early owner,
          please fill out this{' '}
          <a
            className="text-[#4267B2] underline hover:opacity-75"
            href="https://forms.gle/zDLALzvnPKzDPUb59"
            target="_blank"
          >
            brief form
          </a>{' '}
          or reach out to us at{' '}
          <span
            onClick={sendMail}
            className="text-[#4267B2] cursor-pointer underline hover:opacity-75"
          >
            community@evqlabs.com.
          </span>
        </p>
      </div>
      <div
        style={{
          fontFamily:
            'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        }}
        className="mt-[30px] max-sm:mx-auto flex flex-col items-center"
      >
        <p className="mb-3 text-[19.5px] text-center font-medium tracking-wider">
          <a
            href="https://eidverse.substack.com"
            target="_blank"
            className="text-black hover:opacity-75 font-semibold"
          >
            Subscribe to <br />{' '}
            <span className="text-[#555555]">The Founding Community</span>
          </a>
        </p>
        <SubstackWidget />
      </div>

      {/* <form>
        <div className="mt-[30px]">
          <div className="sm:flex justify-center items-center">
            <input
              className="h-auto max-sm:w-[100%] outline-none text-[16px] leading-[22px] px-[12px] py-[16px] text-[#2F2440] border border-solid border-[#E2E0E0] max-sm:mb-[12px]"
              type="email"
              placeholder="Enter your email here"
              required
            />
            <button
              onClick={() => window.open('https://eidverse.substack.com')}
              type="submit"
              style={{ backgroundColor: 'black' }}
              className="h-auto max-sm:w-[100%] text-white uppercase sm:max-w-[460px]  text-[18px] font-bold leading-[22px] px-[12px] py-[16px]"
            >
              Subscribe
            </button>
          </div>
        </div>
      </form> */}
    </div>
  );
};

export default ManifestoContent;

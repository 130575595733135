import React from 'react';

const RoadmapContent = () => {
  return (
    <div className="px-[20px]">
      <div className="max-w-[704px] mt-12 flex flex-col mx-auto">
        <>
          <h2
            className={`sm:text-[34px] text-[28px] sm:mt-[44.5px] mt-[16px] text-[28px]`}
          >
            During the Islamic Golden Age, we could see a powerful economic
            system grow across multiple disciplines fueling remarkable
            advancements.
          </h2>
          <p className="mt-[16px]">
            The Eidverse channels this expansive legacy, and uses modern
            technology on top of that. All governed by the owners of the EID
            token.
          </p>
          <p className="mt-[16px]">
            Shared ownership isn't just a concept—it's a practice. The EID
            owners' actions will be igniting a new era of big, positive,
            enduring advancements.
          </p>
          <p className="mt-[16px]">
            We're committed to transparency in our goals and in the tools we use
            to achieve them.
          </p>
          <p className="mt-[16px]">
            If you're interested in shaping our future as an owner, we're here
            to welcome you as an owner.
          </p>
          <a href="/learn-more" className="mt-[16px] underline">
            Read More...
          </a>
        </>
      </div>
    </div>
  );
};

export default RoadmapContent;

import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function Terms() {
  return (
    <div>
      <Navbar />
      <div className="grid grid-rows-['1fr/auto'] min-h-screen max-sm:text-[16px] sm:text-[20px] ">
        <div className="grid content-center pb-16 lg:pb-24 selection:text-[#ffffff] selection:bg-[#4940e0] px-5">
          <div
            style={{
              paddingLeft: 'calc((100% - 816px) / 2)',
              paddingRight: 'calc((100% - 816px) / 2)',
            }}
            className="grid gap-y-10 px-4"
          >
            <h1 className="max-sm:text-[26px] tracking-wider uppercase sm:text-[32px] my-[16px] pt-5 font-bold">
              Terms Of Service
            </h1>
            <div className="grid gap-y-8 justify-items-start">
              <h2 className="-mb-8 font-bold">Welcome to EVQ Labs!</h2>
              <p>
                We’re thrilled to have you here. Our Terms of Service ("Terms")
                guide your experience with our app, websites, and services
                (collectively, the "Services"). By using our Services, you’re
                agreeing to these Terms, helping us create a safe and
                responsible community. If these Terms don’t work for you, it’s
                okay to step back and not use our Services.
              </p>

              <h2 className="-mb-8 font-bold">Using Our Services</h2>
              <p>
                We’re here to ensure you have a positive experience. You can use
                our Services if you’re legally able to form a binding contract
                with EVQ Labs and follow all applicable laws. When you create an
                account, please provide accurate information. It’s your account,
                so keep your password secure and let us know if anything seems
                off.
              </p>

              <h2 className="-mb-8 font-bold">
                Your License to Use the Services
              </h2>
              <p>
                We offer you a limited, non-exclusive, non-transferable, and
                revocable license to access and enjoy our Services, as long as
                you abide by these Terms. While you’re free to explore and use
                our Services, you may not use our brand and logos independently.
              </p>

              <h2 className="-mb-8 font-bold">Conduct Expectations</h2>
              <p>
                We want everyone to feel safe and respected. You are not allowed
                to use our Services for illegal activities, fraud, or harmful
                behavior. This includes spreading viruses, engaging in spamming
                or phishing, bullying, harassment, or promoting hate speech and
                discrimination.
              </p>

              <h2 className="-mb-8 font-bold">Termination</h2>
              <p>
                We reserve the right to suspend or terminate your access to the
                Services if necessary, without notice. If these Terms are
                violated, we may also take additional legal action to protect
                our community.
              </p>

              <h2 className="-mb-8 font-bold">Our Service Promise</h2>
              <p>
                At EVQ Labs, we aim to provide the best experience possible. Our
                Services are offered "as is" and "as available." We cannot
                guarantee uninterrupted or error-free service. We appreciate
                your understanding and patience.
              </p>

              <h2 className="-mb-8 font-bold">Limitation of Liability</h2>
              <p>
                EVQ Labs is not liable for any damages arising from your use of
                the Services. This includes direct, indirect, incidental,
                punitive, and consequential damages.
              </p>

              <h2 className="-mb-8 font-bold">Your Responsibility</h2>
              <p>
                By using our Services, you agree to indemnify and hold EVQ Labs,
                along with our affiliates, officers, agents, and employees,
                harmless from any claims or demands made by third parties due to
                your actions. This includes any legal fees resulting from your
                use of the Services or violation of these Terms.
              </p>

              <h2 className="-mb-8 font-bold">Governing Law</h2>
              <p>
                Our relationship is governed by the laws of the State of
                Delaware. This helps ensure clarity and fairness without getting
                tangled in conflicting laws.
              </p>

              <h2 className="-mb-8 font-bold">Resolving Disputes</h2>
              <p>
                If any disputes arise, we’re committed to resolving them through
                binding arbitration according to the Commercial Arbitration
                Rules of the American Arbitration Association, conducted in
                Delaware. We believe this approach is fair and efficient.
              </p>

              <h2 className="-mb-8 font-bold">Final Words</h2>
              <p>
                These Terms represent the entire agreement between you and EVQ
                Labs regarding your use of our Services. You might encounter
                additional terms when using certain features, but these will
                align with our mission of safety and responsibility.
              </p>

              <p>
                Thank you for being part of EVQ Labs. If you have any questions
                or need assistance, please reach out to us at{' '}
                <a
                  className="hover:opacity-50 transition-opacity font-bold text-[#000000]"
                  href="mailto:community@evqlabs.com"
                >
                  community@evqlabs.com
                </a>
                . We’re here to help and support you.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Terms;

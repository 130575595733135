import React from 'react';
import Navbar from '../components/Navbar';

const Blog = () => {
  return (
    <div>
      <Navbar />
      <div className="mx-[16px] max-w-[704px] mx-auto px-4">
        <div>
          <h1 className="text-[24px] sm:text-[64px] my-[24px] font-bold">
            Economic System
          </h1>
          <div className="text-[18px] mb-[24px]">
            <strong>
              Afropolitan is creating a Digital Nation to enable all Africans to
              build abundant lives.
            </strong>
          </div>
          <p className="text-[18px] mb-[24px]">
            We are building a network comprising the best that Africa and the
            diaspora offer across art, finance, tech, health, energy, sports,
            and media. The Afropolitan Network is a Curator of Black and
            African:
          </p>
          <ul className="text-[18px] list-disc list-inside pl-2 mb-[24px]">
            <li>Talent (recruit, partner up)</li>
            <li>Culture (art, media, fashion)</li>
            <li>Capital (investment DAOs)</li>
            <li>Information (newsletter, blog, Twitter)</li>
            <li>Experiences (events, curated offerings)</li>
          </ul>
          <p className="text-[18px]">
            Nevertheless, we believe{' '}
            <strong>our reach should exceed our grasp.</strong> While it may be
            a humble Network today, Afropolitan will be the first-ever internet
            country in the future. But we’re getting ahead of ourselves. First,
            you may be asking: why build a Digital Nation?
          </p>
        </div>
        <div>
          <h1 className="text-[24px] my-[24px] font-bold">
            WHY WE’RE BUILDING A DIGITAL NATION
          </h1>
          <div className="text-[18px] mb-[24px] italic">
            <strong>
              Each generation must, out of relative obscurity, discover its
              mission, fulfill it, or betray it.
            </strong>
          </div>
          <p className="text-[18px] mb-[24px]">— Frantz Fanon</p>
          <p className="text-[18px] mb-[24px]">
            Scarcity. Weakness. Poverty. Wherever we look and wherever we are,
            Africans worldwide are subjects, not citizens. Access to opportunity
            is scarce. Security is not guaranteed. For the most part, life is
            just about surviving, not thriving.
          </p>
          <p className="text-[18px] mb-[24px]">
            What would you do if you could do anything? If your future was
            boundless, not bounded? What if your allegiance to a country did not
            depend on birth but on choice? In short: What would happen if you
            could come together with others to build a new society?
          </p>
          <p className="text-[18px] mb-[24px]">
            We know what you’re thinking: “This is unrealistic .” Everything
            around you, from the machine on which you’re reading this manifesto
            to the country you live in, was once an idea in somebody’s head.
            They only came to be thanks to the work of millions of people, no
            more intelligent or better than you.
          </p>
          <p className="text-[18px] mb-[24px]">
            In this spirit, and with humility and hope for the future, we
            Afropolitans declare:
          </p>
          <ul className="text-[18px] list-decimal list-inside pl-2 mb-[24px]">
            <li>
              The nation-state experiment has failed for Black people worldwide.
              It has yielded nothing but poverty, genocide, police brutality,
              ethnic strife, inflation, weak government, and the failure of our
              ecosystems.
            </li>
            <li>
              All people who call themselves free have a fundamental right to
              create the society they want by choice collectively. As the
              internet enables us to shrink space and form bonds across the
              planet, no person should live in a society by accident or force.
            </li>
            <li>
              Finally, that Abundance and the destruction of scarcity is a North
              Star worth pursuing in any society which hopes to be both healthy
              and wealthy. Instead of the Land of Opportunity, we propose the
              Network of Abundance: Abundance of Tools, Abundance of
              Opportunity, Abundance of Joy.
            </li>
          </ul>
          <p className="text-[18px] italic font-bold">
            To all future Afropolitans, our deal is simply this: join the
            Network of Abundance to be all you can be. Afropolitan is the
            canvas; you wield the brush.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Blog;

import React from 'react';
import { sendMail } from '../../functions/usefulFunctions';
import cover from './../../resources/Images/team-cover2.jpg';

const TeamContent = () => {
  return (
    <div className="max-w-[704px] mx-auto max-sm:px-[32px] sm:px-[10px] pt-[50px] pb-[120px] flex flex-col">
      <div>
        <h1 className="max-sm:text-[26px] tracking-wider uppercase sm:text-[32px] mt-[16px] font-bold">
          Our Team and the EID Protocol
        </h1>
        {/* <div className="flex justify-center max-sm:mb-4 sm:mb-3 max-sm:mt-3 sm:mt-1">
          <img src={cover} width="80%" alt="" />
        </div> */}
        <div className="max-sm:text-[18px] sm:text-[22px] mt-[32px] mb-[28px]">
          <p>
            The EID Protocol emerged from a collaborative effort at EVQ Labs,
            combining expertise in global consumer technology, finance,
            blockchain, and social innovation. <br />
            <br /> Our aim is to create a decentralized ecosystem that fosters
            global innovation and inclusivity inspired by the Islamic Golden
            Age.
          </p>
        </div>
      </div>
      <div>
        <p className="font-medium sm:text-[22px] max-sm:text-[18px]">
          EVQ Labs: Initiating the EID Protocol
        </p>{' '}
        <br />
        <div className="max-sm:text-[18px] sm:text-[22px] mb-[28px] text-[#383838]">
          <p>
            As the initial contributor, EVQ Labs is responsible for putting in
            place key constituents of the initial EID ecosystem, including
            launching the EID token and managing the EID app, the first gateway
            for user interaction with the ecosystem. <br />
            <br /> Our leadership is based in Dubai and New York but our team is
            global.
          </p>
        </div>
      </div>
      <div>
        <p className="font-medium sm:text-[22px] max-sm:text-[18px]">
          Join Us in Shaping the Eidverse
        </p>{' '}
        <br />
        <div className="max-sm:text-[18px] sm:text-[22px] mb-[28px] text-[#383838]">
          <p>
            We believe in open dialogue and community participation. If you have
            ideas, questions, or want to be part of this journey, feel free to
            connect with us at{' '}
            <span
              onClick={sendMail}
              className="text-[#3e65b2] cursor-pointer hover:opacity-75 underline"
            >
              community@evqlabs.com.
            </span>{' '}
            Your insights and contributions are invaluable in shaping the
            Eidverse.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeamContent;

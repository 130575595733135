const RoadmapData = [
  {
    title: 'A Decentralized Patronage and Financial Ecosystem',
    content: '',
    sections: [
      {
        subtitle: 'Introduction to the Eidverse',
        subcontent:
          'The Eidverse aims to be a community-owned initiative inspired by the Islamic Golden Age, an era renowned for its remarkable advancements across the sciences, arts, commerce, and technology. Our mission is to emulate the mechanics that led to the flourishing of this era, with a particular emphasis on shared ownership, partnership, and support, including funding.\n\nAchieving this mission requires the mutually beneficial participation of many individuals, and for the first time, current technologies that enable verifiable ownership make this possible. By making use of them, the Eidverse seeks to provide a platform where its owners can come together, share resources, and drive initiatives that lead to big, positive, and enduring outcomes similar in spirit and legacy to that of the Islamic Golden Age.',
      },
      {
        subtitle: 'An Ecosystem of Support',
        subcontent:
          'The heart of the Eidverse is the EID token, a representation of shared ownership within the ecosystem and a name that evokes mutual celebration, a cornerstone of our ethos. This shared ownership is not a peripheral feature but a core mechanism that drives the development and success of the Eidverse. The EID token is designed to dynamically shift ownership towards those who are most likely to contribute to the mission of the Eidverse, fostering a sense of collective responsibility, sustained renewal, and shared success.\n\nThe Eidverse leverages Web3 technology to enable this shared ownership model. As an open-source protocol, it serves as a foundational layer for further development and innovation. The next key milestone for the Eidverse is the launch of the EID token on the mainnet, which will reflect the initial ownership positions established through the exchange of Web2 tokens available on the EID Award App.\n\nBeyond the launch, the focus will turn towards enabling innovative, user-friendly services, such as pooling, that facilitate the intentional support of individuals and projects in alignment with the mission of the Eidverse.\n\nThe success of the Eidverse is deeply rooted in the trust and collaboration within its community. Whether the EID token accrues financial value or serves primarily as a currency of support among its members, it is the collective effort of the community, underpinned by shared ownership, that will drive the Eidverse towards its mission of facilitating big, positive, and enduring outcomes.\n\nThis working whitepaper outlines the inspiration, system, technology, and roadmap of the Eidverse, detailing our vision for a new layer of social infrastructure. Please expect it to evolve significantly as we gather feedback from the community.',
      },
    ],
  },
  {
    title: 'The Vision and Approach of the Eidverse',
    content:
      'This section delves deeper into the foundational principles that guide the Eidverse, its strategic mechanisms, and the role of its community.',
    sections: [
      {
        subtitle: 'Inspiration from the Islamic Golden Age',
        subcontent:
          'The Eidverse is deeply influenced by the Islamic Golden Age, a period marked by significant advancements across domains of knowledge, expression, and utility. This era was characterized by mechanisms of patronage, collaboration, and shared knowledge. Scholars and innovators from different backgrounds contributed to a collective pool of knowledge. The Eidverse aims to emulate these dynamics, fostering a culture of shared ownership and collaboration.',
      },
      {
        subtitle: 'The Need for a New Social Infrastructure',
        subcontent:
          "In today's world, large-scale collaboration and shared ownership are often hindered by various challenges. Traditional systems often promote competition over collaboration and concentrate ownership in the hands of a few. There is a need for a new social infrastructure that can facilitate large-scale collaboration and shared ownership, fostering a sense of collective responsibility and shared success.",
      },
      {
        subtitle: 'The Eidverse Approach',
        subcontent:
          "The Eidverse is designed to address these challenges. Leveraging Web3 technology, it enables shared ownership and fosters collaboration on a large scale. At the heart of the Eidverse is the EID token, a representation of shared ownership within the ecosystem. The EID token is structured to shift ownership towards those actively contributing to the Eidverse's mission.",
      },
      {
        subtitle: 'The Power of Shared Ownership',
        subcontent:
          'Shared ownership is not a peripheral feature of the Eidverse; it is a core mechanism that drives its development and success. By allowing ownership to shift dynamically, the Eidverse fosters a sense of collective responsibility. The EID token, beyond its potential financial value, serves as a testament to this shared ownership, binding the community in their collective mission.',
      },
      {
        subtitle: 'The Role of the Community',
        subcontent:
          "The community is the driving force behind the Eidverse. It's not just a group of token holders but a collective working towards a shared vision. Their combined efforts, underpinned by the principle of shared ownership, are crucial for the success of the Eidverse.",
      },
    ],
  },
];

export default RoadmapData;

import React, { useEffect, useState } from 'react';
import Logo from './../resources/Images/eidverseLogo.png';
import twitterLogo from './../resources/Images/twitter-white.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GoX } from 'react-icons/go';

import { FaDiscord, FaInstagram, FaTwitter } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const goToHome = () => {
    window.location.pathname = '/';
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location.pathname]);

  return (
    <div
      className={`leading-[25px] pt-3 sm:px-16 text-[16px] flex items-center justify-between relative ${
        isOpen
          ? 'bg-black text-[#FFF8E7] transition-all duration-300'
          : 'transition-all duration-300'
      }`}
    >
      <div
        onClick={goToHome}
        className="pl-5 flex items-center py-4 cursor-pointer"
      >
        <img src={Logo} width="30px" height="28px" />
        <span className="ml-2 text-[20px] font-medium">Eidverse F.C.</span>
      </div>

      <div
        onClick={toggleDrawer}
        className={`p-4 pr-5 -mt-[1px] cursor-pointer ${
          isOpen ? 'text-[#FFF8E7]' : 'text-black'
        }`}
      >
        <span className=" text-2xl">
          {!isOpen ? <GiHamburgerMenu /> : <GoX />}
        </span>
      </div>
      <div
        className={`flex flex-col max-sm:pl-5 sm:pl-[5.5rem] text-[16px] bg-black absolute top-full left-0 right-0 overflow-hidden transition-all duration-300 text-[#FFF8E7] ${
          isOpen ? 'h-[40vh]' : 'h-0'
        }`}
        style={{
          backdropFilter: isOpen ? 'blur(20px)' : 'none',
        }}
      >
        {/* <a href="/manifesto" className="py-4 ">
            Manifesto
          </a> */}
        <a
          href="/our-message"
          className="py-1 flex items-center sm:pt-5 gap-x-2 relative before:transition-all before:duration-200 before:ease-out before:absolute before:bottom-0 before:h-px before:bg-current before:w-0 lg:hover:before:w-[225px] hover:text-[#f2f4f7] whitespace-nowrap"
        >
          Letter From the Founding Team
        </a>
        <a
          href="/overview"
          className="py-1 flex items-center gap-x-2 relative before:transition-all before:duration-200 before:ease-out before:absolute before:bottom-0 before:h-px before:bg-current before:w-0 lg:hover:before:w-[146px] hover:text-[#f2f4f7] whitespace-nowrap"
        >
          Ecosystem Overview
        </a>
        <a
          href="/our-team"
          className="py-1 flex items-center gap-x-2 relative before:transition-all before:duration-500 before:ease-out before:absolute before:bottom-0 before:h-px before:bg-current before:w-0 lg:hover:before:w-[68px] hover:text-[#f2f4f7] whitespace-nowrap"
        >
          Our Team
        </a>
        <a
          href="/privacy"
          className="py-1 flex items-center gap-x-2 relative before:transition-all before:duration-500 before:ease-out before:absolute before:bottom-0 before:h-px before:bg-current before:w-0 lg:hover:before:w-[96px] hover:text-[#f2f4f7] whitespace-nowrap"
        >
          Privacy Policy
        </a>
        <a
          href="/terms"
          className="py-1 flex items-center gap-x-2 relative before:transition-all before:duration-500 before:ease-out before:absolute before:bottom-0 before:h-px before:bg-current before:w-0 lg:hover:before:w-[116px] hover:text-[#f2f4f7] whitespace-nowrap"
        >
          Terms of Service
        </a>
        {/* <a
            href="https://twitter.com/EidverseFC"
            className="py-4 flex items-center gap-x-2"
          >
            Twitter
          </a>
          <a
            href="https://discord.gg/DZbCNSMp6C"
            className="py-4 flex items-center gap-x-2"
          >
            Discord
          </a> */}
        <div className="flex max-sm:justify-center items-center sm:pl-[5.8rem] text-[24px] pb-4 absolute bottom-3 left-0 right-0 px-auto ">
          <a
            href="https://twitter.com/EidverseFC"
            className="max-sm:px-4 hover:opacity-75"
          >
            <img src={twitterLogo} width="18px" />
          </a>
          <a
            href="https://discord.gg/DZbCNSMp6C"
            className="px-4 hover:opacity-75"
          >
            <FaDiscord />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

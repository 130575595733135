import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import Blog from './screens/Blog';
import Clone from './screens/Clone';
import Contact from './screens/Contact';
import LandingPage from './screens/Landing';
import LearnMore from './screens/LearnMore';
import Manifesto from './screens/Manifesto';
import OurLetter from './screens/OurLetter';
import PledgeSupport from './screens/PledgeSupport';
import Privacy from './screens/Privacy';
import Roadmap from './screens/Roadmap';
import Team from './screens/Team';
import Terms from './screens/Terms';
import Whitepaper from './screens/Whitepaper';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
      </Routes>
      <Routes>
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
      <Routes>
        <Route exact path="/pledge-support" element={<PledgeSupport />} />
      </Routes>
      <Routes>
        <Route exact path="/privacy" element={<Privacy />} />
      </Routes>
      <Routes>
        <Route exact path="/terms" element={<Terms />} />
      </Routes>
      <Routes>
        <Route exact path="/whitepaper" element={<Whitepaper />} />
      </Routes>
      <Routes>
        <Route exact path="/clone" element={<Clone />} />
      </Routes>
      <Routes>
        <Route exact path="/roadmap" element={<Roadmap />} />
      </Routes>
      <Routes>
        <Route exact path="/learn-more" element={<LearnMore />} />
      </Routes>
      <Routes>
        <Route exact path="/blog" element={<Blog />} />
      </Routes>
      <Routes>
        <Route exact path="/our-team" element={<Team />} />
      </Routes>
      <Routes>
        <Route exact path="/our-message" element={<OurLetter />} />
      </Routes>
      <Routes>
        <Route exact path="/overview" element={<Manifesto />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;

import React, { useRef, useEffect } from 'react';

const RoadmapDetails = ({ data, activeTitle, activeSubtitle }) => {
  const contentRefs = useRef([]);

  useEffect(() => {
    contentRefs.current = contentRefs.current.slice(0, data.length);
  }, [data]);

  useEffect(() => {
    if (
      activeSubtitle !== -1 &&
      contentRefs.current[activeTitle]?.subcontents[activeSubtitle]
    ) {
      const subcontentRef =
        contentRefs.current[activeTitle].subcontents[activeSubtitle];
      subcontentRef.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeTitle, activeSubtitle]);

  const renderContentWithNewlinesAndBold = (content) => {
    const paragraphs = content
      .split('\n' && '\n\n')
      .map((paragraph, index) => (
        <p
          key={index}
          className="mt-4 text-[16px] leading-[28px]"
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      ));

    return paragraphs;
  };

  return (
    <div
      className="md:w-[680px] xs:w-[370px] w-[340px] md:ml-[145px] xs:ml-3 ml-5 pt-[48px] "
      style={{ fontFamily: 'Source Serif Pro' }}
    >
      <h1
        className="text-lg mb-8 text-[#6a6969]"
        style={{ fontFamily: 'Source Sans Pro' }}
      >
        EID PROTOCOL WHITEPAPER
      </h1>
      {data.map((section, index) => (
        <div key={index} id={`content-${index}`} className=" mb-16">
          <h2
            className="md:text-[35px] text-[26px] mb-[28px]  leading-tight"
            id={`section-${index}`}
            style={{ fontFamily: 'Source Sans Pro' }}
          >
            {section.title}
          </h2>
          <p className="text-[16px]">
            {renderContentWithNewlinesAndBold(section.content)}
          </p>
          {section.sections.map((section, subIndex) => (
            <div
              key={subIndex}
              id={`content-${index}-${subIndex}`}
              className="content-section"
              ref={(el) =>
                (contentRefs.current[index] = {
                  subcontents: [
                    ...(contentRefs.current[index]?.subcontents || []),
                    el,
                  ],
                })
              }
            >
              <h3
                className="md:text-[28px] text-[24px] mt-[16px]"
                style={{ fontFamily: 'Source Sans Pro' }}
              >
                {section.subtitle}
              </h3>
              <p className="text-[16px]">
                {renderContentWithNewlinesAndBold(section.subcontent)}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RoadmapDetails;

import React from 'react';
import Footer from '../components/Footer';
import ManifestoContent from '../components/ManifestoContent';
import ManifestoHero from '../components/ManifestoHero';
import Navbar from '../components/Navbar';

const Manifesto = () => {
  return (
    <>
      <Navbar />
      {/* <ManifestoHero /> */}
      <ManifestoContent />
      <Footer />
    </>
  );
};

export default Manifesto;

import React from "react";
import {BiX} from "react-icons/bi";

const BlackTopbar = () => {
  return (
    <div>
      <div className="max-lg:hidden">
        <div style={{}} className="grid lg:h-[33px] text-[14px] px-4">
          <div className="fixed z-10 inset-x-0 top-0 text-[#ffffff]">
            <div className="lg:py-2 bg-[#000000] text-center">
              <b>Preliminary Draft:</b> The contents of this whitepaper are
              subject to significant revisions and may change entirely. This is
              a working draft and does not provide a comprehensive or final
              representation of the ecosystem.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackTopbar;

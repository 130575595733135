import React from "react";
import Footer from "../components/Footer";
import FooterText from "../components/FooterText";
import Logo from "../resources/Images/Logo.png";

function Contact() {
  return (
    <div className="grid grid-rows-['1fr/auto'] min-h-screen">
      <div className="grid content-center pb-16 lg:pb-24 selection:text-[#ffffff] selection:bg-[#4940e0] px-5">
        <div className="lg:pt-[18px] lg:pb-0 mt-10">
          <div
            style={{
              paddingLeft: "calc((100% - 816px) / 2)",
              paddingRight: "calc((100% - 816px) / 2)",
            }}
            className="grid lg:h-[68px] text-[18px] font-medium px-4"
          >
            <a
              className="flex self-center items-center text-[#000000]"
              href="/"
            >
              <span className=" inline-block pointer-events-none w-[188px] h-8">
                <div>
                  <a
                    className="flex self-center items-center w-40 lg:w-52"
                    href="/"
                  >
                    {/* <img src={Logo} alt="logo" /> */}
                  </a>
                </div>
              </span>
            </a>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "calc((100% - 816px) / 2)",
            paddingRight: "calc((100% - 816px) / 2)",
          }}
          className="grid gap-y-16 lg:gap-y-10 px-4"
        >
          <h1 className="md:leading-none text-[44px] lg:mt-0 -mt-5 lg:text-[64px] font-bold">
            Contact Us
          </h1>
          <div className="grid gap-y-4 justify-items-start px-2">
            <p className="text-[18px] lg:text-[16px]">
              General and Press Inquiries
            </p>
            <a
              className="hover:opacity-50 transition-opacity leading-7 lg:leading-none text-[28px] lg:text-[20px] font-bold text-[#000000]"
              href="mailto:community@evqlabs.com"
            >
              community@evqlabs.com
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;

const WhitepaperData = [
  {
    title: "A Decentralized Patronage and Financial Ecosystem",
    content: "",
    sections: [
      {
        subtitle: "Introduction to the Eidverse",
        subcontent:
          "The Eidverse aims to be a community-owned initiative inspired by the Islamic Golden Age, an era renowned for its remarkable advancements across the sciences, arts, commerce, and technology. Our mission is to emulate the mechanics that led to the flourishing of this era, with a particular emphasis on shared ownership, partnership, and support, including funding.\n\nAchieving this mission requires the mutually beneficial participation of many individuals, and for the first time, current technologies that enable verifiable ownership make this possible. By making use of them, the Eidverse seeks to provide a platform where its owners can come together, share resources, and drive initiatives that lead to big, positive, and enduring outcomes similar in spirit and legacy to that of the Islamic Golden Age.",
      },
      {
        subtitle: "An Ecosystem of Support",
        subcontent:
          "The heart of the Eidverse is the EID token, a representation of shared ownership within the ecosystem and a name that evokes mutual celebration, a cornerstone of our ethos. This shared ownership is not a peripheral feature but a core mechanism that drives the development and success of the Eidverse. The EID token is designed to dynamically shift ownership towards those who are most likely to contribute to the mission of the Eidverse, fostering a sense of collective responsibility, sustained renewal, and shared success.\n\nThe Eidverse leverages Web3 technology to enable this shared ownership model. As an open-source protocol, it serves as a foundational layer for further development and innovation. The next key milestone for the Eidverse is the launch of the EID token on the mainnet, which will reflect the initial ownership positions established through the exchange of Web2 tokens available on the EID Award App.\n\nBeyond the launch, the focus will turn towards enabling innovative, user-friendly services, such as pooling, that facilitate the intentional support of individuals and projects in alignment with the mission of the Eidverse.\n\nThe success of the Eidverse is deeply rooted in the trust and collaboration within its community. Whether the EID token accrues financial value or serves primarily as a currency of support among its members, it is the collective effort of the community, underpinned by shared ownership, that will drive the Eidverse towards its mission of facilitating big, positive, and enduring outcomes.\n\nThis working whitepaper outlines the inspiration, system, technology, and roadmap of the Eidverse, detailing our vision for a new layer of social infrastructure. Please expect it to evolve significantly as we gather feedback from the community.",
      },
    ],
  },
  {
    title: "The Vision and Approach of the Eidverse",
    content:
      "This section delves deeper into the foundational principles that guide the Eidverse, its strategic mechanisms, and the role of its community.",
    sections: [
      {
        subtitle: "Inspiration from the Islamic Golden Age",
        subcontent:
          "The Eidverse is deeply influenced by the Islamic Golden Age, a period marked by significant advancements across domains of knowledge, expression, and utility. This era was characterized by mechanisms of patronage, collaboration, and shared knowledge. Scholars and innovators from different backgrounds contributed to a collective pool of knowledge. The Eidverse aims to emulate these dynamics, fostering a culture of shared ownership and collaboration.",
      },
      {
        subtitle: "The Need for a New Social Infrastructure",
        subcontent:
          "In today's world, large-scale collaboration and shared ownership are often hindered by various challenges. Traditional systems often promote competition over collaboration and concentrate ownership in the hands of a few. There is a need for a new social infrastructure that can facilitate large-scale collaboration and shared ownership, fostering a sense of collective responsibility and shared success.",
      },
      {
        subtitle: "The Eidverse Approach",
        subcontent:
          "The Eidverse is designed to address these challenges. Leveraging Web3 technology, it enables shared ownership and fosters collaboration on a large scale. At the heart of the Eidverse is the EID token, a representation of shared ownership within the ecosystem. The EID token is structured to shift ownership towards those actively contributing to the Eidverse's mission.",
      },
      {
        subtitle: "The Power of Shared Ownership",
        subcontent:
          "Shared ownership is not a peripheral feature of the Eidverse; it is a core mechanism that drives its development and success. By allowing ownership to shift dynamically, the Eidverse fosters a sense of collective responsibility. The EID token, beyond its potential financial value, serves as a testament to this shared ownership, binding the community in their collective mission.",
      },
      {
        subtitle: "The Role of the Community",
        subcontent:
          "The community is the driving force behind the Eidverse. It's not just a group of token holders but a collective working towards a shared vision. Their combined efforts, underpinned by the principle of shared ownership, are crucial for the success of the Eidverse.",
      },
    ],
  },
  {
    title: "System and Technology",
    content:
      "The technological infrastructure of the Eidverse is tailored to support its foundational principles of shared ownership, collaboration, and innovation. This section elaborates on the key technological components that underpin the Eidverse's vision.",
    sections: [
      {
        subtitle: "Shared Ownership",
        subcontent:
          "At its core, the Eidverse seeks to decentralize ownership, allowing for a more equitable distribution of influence and benefits. Traditional platforms often centralize power, limiting community input. In contrast, the Eidverse's technology is structured to empower its community, ensuring that ownership isn't just symbolic but has tangible implications in decision-making and platform evolution.",
      },
      {
        subtitle: "EID Token",
        subcontent: `<b>Purpose:</b> The EID token is more than just a digital asset; it embodies the ethos of the Eidverse. It serves as a representation of one's stake in the ecosystem, ensuring that active contributors are recognized and rewarded.\n\n<b>Utility:</b> Beyond representation, the EID token will have various utilities within the Eidverse, from accessing specific features to participating in governance decisions.`,
      },
      {
        subtitle: "Dynamic Ownership through Positive Marginal Transfers",
        subcontent: `<b>Pooling Mechanism:</b> The Eidverse introduces a pooling mechanism as a primary method of transferring ownership. By pooling resources, members can collectively support initiatives or individuals that align with the Eidverse's mission. This ensures that ownership is channeled towards those who are best positioned to drive the mission forward.\n\n<b>Peer-to-Peer Awarding:</b> Beyond pooling, the Eidverse emphasizes the importance of peer recognition. Members can directly award tokens to others, recognizing their contributions and transferring a portion of ownership to them. This act not only rewards contributors but also strengthens the community's interconnectedness.\n\n<b>Empowering Active Contributors:</b> Both pooling and peer-to-peer awarding are designed to ensure that active contributors, those who are genuinely invested in the Eidverse's objectives, hold a significant stake in the ecosystem. This decentralized approach to ownership ensures that the platform remains aligned with its mission and values.
`,
      },
      {
        subtitle: "Web3 Technology",
        subcontent: `<b>Decentralization:</b> Web3 technology is the backbone of the Eidverse's decentralized approach. Unlike Web2, where data is controlled by centralized entities, Web3 ensures that data ownership is returned to the users.\n\n<b>Transparency and Security:</b> Every transaction within the Eidverse is recorded on a blockchain, ensuring transparency. This immutable record provides security, ensuring that transactions are tamper-proof.\n\n<b>Community Empowerment:</b> Web3's decentralized nature means that power isn't concentrated. The community has a more significant role in decision-making, aligning with the Eidverse's mission of shared ownership.`,
      },
      {
        subtitle: "Wallet and Account Abstraction",
        subcontent: `<b>User Accessibility:</b> Recognizing that blockchain interactions can be complex, the Eidverse provides a wallet solution that abstracts many of the complexities. This ensures that even those new to blockchain can engage with the platform seamlessly.\n\n<b>Integration:</b> By integrating with familiar platforms like Google accounts, the Eidverse reduces the entry barriers, allowing for a broader audience to participate.\n\n<b>Cost Efficiency:</b> The wallet solution is also designed to address common blockchain challenges, such as fluctuating gas fees, ensuring that users can transact cost-effectively.`,
      },
      {
        subtitle: "Token Distribution and Mainnet Launch",
        subcontent: `<b>Phased Approach:</b> The introduction of the EID token follows a phased approach. Initially, Web2 tokens act as placeholders, setting the stage for the mainnet launch.\n\n<b>Transition:</b> As the platform transitions to the mainnet, these Web2 tokens will be exchanged for Web3 tokens, marking a pivotal moment in the Eidverse's evolution.`,
      },
      {
        subtitle: "Future Developments",
        subcontent: `<b>Adaptability:</b> Technology is ever-evolving, and the Eidverse is committed to staying at the forefront. This means continuously exploring new technological advancements that can enhance the platform.\n\n<b>Community Feedback:</b> As the platform grows, community feedback will play a crucial role in shaping its direction. The Eidverse will actively seek input, ensuring that developments align with community needs and aspirations. \n\nThe technological foundation of the Eidverse is designed to create an environment that supports shared ownership, collaboration, and innovation, combining historical principles with modern technologies.`,
      },
    ],
  },
  {
    title: "Tokenomics and Distribution",
    content:
      "The EID token's design and distribution strategy are central to the Eidverse's vision of shared ownership and collaboration. This section provides an overview of the token's economic model and its allocation.",
    sections: [
      {
        subtitle: "Role of the EID Token",
        subcontent:
          "The EID token serves multiple purposes within the Eidverse. Beyond its function as a digital asset, it acts as a symbol of community involvement and commitment. The token facilitates collaborative efforts, allowing members to support projects and initiatives that align with the Eidverse's mission.",
      },
      {
        subtitle: "Distribution Strategy",
        subcontent: `The allocation of the EID token has been planned to ensure a balanced distribution among various stakeholders. This includes community members, the Eidverse team, early supporters, and investors. A fair distribution is essential to maintain the principle of shared ownership and to prevent centralization of power.`,
      },
      {
        subtitle: "Governance and Decision Making",
        subcontent: `EID token holders have a say in the direction and decisions of the Eidverse. The token grants its holders voting rights on proposals, changes, and new initiatives. This decentralized approach to governance ensures that the community's voice is heard and that decisions align with the collective interest.`,
      },
      {
        subtitle: "Rewards and Incentives",
        subcontent: `To encourage active participation and contribution, the Eidverse has set up a reward system. EID token holders can earn rewards for their involvement in various activities, from promoting the platform to participating in community-driven projects. These incentives aim to foster a proactive and engaged community.`,
      },
      {
        subtitle: "Safety Measures",
        subcontent: `The integrity and security of the EID token are paramount. Measures are in place to prevent potential issues such as market manipulation or concentration of tokens. Regular audits, transparent reporting, and community oversight ensure the token's stability and trustworthiness.\nThe EID token's economic model and distribution strategy are designed to support the Eidverse's overarching goals, emphasizing shared ownership, collaboration, and community engagement.`,
      },
    ],
  },
  {
    title: "Ownership Levels and Progression",
    content:
      "In the Eidverse, ownership isn't merely a static designation; it's a dynamic reflection of an individual's or entity's engagement, contribution, and alignment with the platform's ethos.",
    sections: [
      {
        subtitle: "Dynamic Ownership Tiers",
        subcontent:
          "The Eidverse categorizes ownership into distinct levels, each representing a different degree of engagement and contribution. These aren't just titles; they come with specific criteria that members must meet or exceed.",
      },
      {
        subtitle: "Criteria-Based Progression",
        subcontent: `Progressing through ownership levels isn't arbitrary. It's based on clear criteria, such as the duration of involvement, amount of EID tokens held, participation in governance decisions, and contributions to community initiatives.`,
      },
      {
        subtitle: "Benefits and Responsibilities",
        subcontent: `Each ownership level brings with it a unique set of benefits, from increased voting power in governance decisions to early access to new features. However, with these benefits come responsibilities. Higher ownership levels are expected to play a more active role in guiding and nurturing the community, ensuring the Eidverse remains true to its vision.`,
      },
    ],
  },
  {
    title: "Peer-to-Peer Token Gifting",
    content:
      "The act of gifting tokens within the Eidverse transcends mere transactional exchanges, embodying deeper values of trust, acknowledgment, and community cohesion.",
    sections: [
      {
        subtitle: "Affirmation of Trust",
        subcontent:
          "When an individual gifts EID tokens to another member, it's not just a transfer of assets. It's an affirmation of trust, a gesture that signifies belief in the recipient's potential or the value they bring to the Eidverse.",
      },
      {
        subtitle: "Community Cohesion",
        subcontent: `Token gifting acts as a mechanism to strengthen community bonds. It fosters a culture of mutual support, where members actively look out for one another, recognizing and rewarding contributions that might otherwise go unnoticed.`,
      },
      {
        subtitle: "Pre-Launch Emphasis",
        subcontent: `Especially during the pre-launch phase, token gifting plays a pivotal role. It sets the foundational ethos for the Eidverse, emphasizing the importance of community over individual gain and shared success over isolated achievements.`,
      },
    ],
  },
  {
    title: "Community Engagement and Growth",
    content:
      "The Eidverse recognizes that its strength and potential are deeply rooted in its community. This section delves into the strategies and tools employed to nurture, grow, and engage with the community.",
    sections: [
      {
        subtitle: "Building a Diverse Community",
        subcontent:
          "The Eidverse seeks to attract individuals from various backgrounds, expertise, and perspectives. This diversity is essential to foster innovation, creativity, and a holistic approach to problem-solving, reminiscent of the collaborative spirit of the Islamic Golden Age.",
      },
      {
        subtitle: "Platforms for Engagement",
        subcontent: `The Eidverse utilizes platforms like Discord to facilitate real-time communication, collaboration, and knowledge-sharing among its members. Regular webinars, Q&A sessions, and community meetups are organized to keep members informed, involved, and inspired.`,
      },
      {
        subtitle: "Feedback and Iteration",
        subcontent: `Open channels are maintained for community members to provide feedback, voice concerns, or suggest improvements. This feedback loop ensures that the Eidverse evolves in a direction that resonates with its community's needs and aspirations.`,
      },
      {
        subtitle: "Educational Initiatives",
        subcontent: `To empower its members and foster informed participation, the Eidverse offers educational resources and workshops. These initiatives cover topics ranging from the basics of blockchain technology to the intricacies of the Eidverse's vision and mechanics.`,
      },
      {
        subtitle: "Recognition and Rewards",
        subcontent: `Active contributors, innovators, and community leaders are recognized and rewarded for their efforts. This not only incentivizes participation but also instills a sense of pride and ownership among members.`,
      },
      {
        subtitle: "Collaborative Projects",
        subcontent: `The Eidverse encourages its members to initiate and participate in collaborative projects. These projects, aligned with the platform's vision, serve as practical applications of the principles of shared ownership and collective effort.\nThe community is the lifeblood of the Eidverse. By fostering a proactive, engaged, and diverse community, the Eidverse aims to achieve its mission of recreating the collaborative spirit of the Islamic Golden Age in a digital era.`,
      },
    ],
  },
  {
    title: "Future Roadmap and Development",
    content:
      "The journey of the Eidverse is one of continuous evolution, driven by both its foundational vision and the ever-changing landscape of the digital world.",
    sections: [
      {
        subtitle: "Upcoming Milestones",
        subcontent:
          "The Eidverse has charted out key milestones that represent significant advancements or shifts in its journey. These aren't just technical or developmental markers but also include community growth targets, partnerships, and integrations.",
      },
      {
        subtitle: "Adaptive Planning",
        subcontent: `While a roadmap provides direction, the Eidverse recognizes the need for adaptability. As such, the roadmap is periodically reviewed and adjusted based on technological advancements, community feedback, and shifts in the broader digital landscape.`,
      },
      {
        subtitle: "Collaborative Development",
        subcontent: `The future of the Eidverse isn't determined solely by its core team. Through community forums, hackathons, and partnerships, the platform encourages collaborative development, ensuring that its evolution is holistic and inclusive.`,
      },
    ],
  },
  {
    title: "Conclusion",
    content:
      "The Eidverse is more than just a digital platform; it's a movement. Drawing inspiration from the Islamic Golden Age, it seeks to foster an era of digital collaboration, innovation, and shared ownership. By leveraging cutting-edge technology and fostering a vibrant, engaged community, the Eidverse aims to create a space where collective efforts lead to monumental achievements. As we embark on this journey, we invite every reader, every potential member, to join us, to contribute, and to be a part of this transformative vision.",
    sections: [
      {
        subtitle: "",
        subcontent: "",
      },
    ],
  },
];

export default WhitepaperData;

import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';

const UserDetailsForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [data, setData] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      Name: name,
      Email: email,
      LinkedIn: linkedIn,
    };
    axios
      .post(
        'https://sheet.best/api/sheets/4bb4d3b8-8aa8-45bb-8c6c-a90b52ce7b9d',
        data,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast.success('Successfully added to the list');
        }
        setData();
        setName('');
        setEmail('');
        setLinkedIn('');
      });
  };

  //   const getData = () => {
  //     axios
  //       .get('https://sheet.best/api/sheets/4bb4d3b8-8aa8-45bb-8c6c-a90b52ce7b9d')
  //       .then((response) => {
  //         setData(response.data);
  //       });
  //   };

  //   useEffect(() => {
  //     getData();
  //   }, [data]);
  return (
    // <div className="container">
    //   <br></br>
    //   <h1>Save Form Data in Google Sheets using React</h1>
    //   <br></br>
    //   <form autoComplete="off" className="form-group" onSubmit={handleSubmit}>
    //     <label>Name</label>
    //     <input
    //       type="text"
    //       className="form-control"
    //       required
    //       placeholder="Enter your name"
    //       onChange={(e) => setName(e.target.value)}
    //       value={name}
    //     />
    //     <br></br>
    //     <label>Email</label>
    //     <input
    //       type="text"
    //       className="form-control"
    //       required
    //       placeholder="Enter your email"
    //       onChange={(e) => setEmail(e.target.value)}
    //       value={email}
    //     />
    //     <br></br>
    //     <label>LinkedIn</label>
    //     <input
    //       type="text"
    //       className="form-control"
    //       required
    //       placeholder="Enter your linkedin"
    //       onChange={(e) => setLinkedIn(e.target.value)}
    //       value={linkedIn}
    //     />
    //     <br></br>
    //     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //       <button type="submit" className="btn btn-primary">
    //         Submit
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <div className='max-sm:mx-3'>
      <Toaster />
      <form autoComplete="off" className="form-group" onSubmit={handleSubmit}>
        <div className="border-2 border-[#454545] pt-[32px] pl-[30px] rounded-[10px] max-w-[500px]">
          <input
            placeholder="Type your name here"
            className="user-details-input"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <input
            placeholder="Type your email here"
            className="user-details-input mt-[32px]"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <input
            placeholder="Add your LinkedIn profile (optional)"
            className="user-details-input mt-[32px] mb-[46px]"
            onChange={(e) => setLinkedIn(e.target.value)}
            value={linkedIn}
          />
        </div>
        <div className="w-[221px] h-[42px] flex justify-center items-center mt-[18px] bg-black text-white rounded-[10px]">
          <button type="submit">Pledge Your Support</button>
        </div>
      </form>
    </div>
  );
};

export default UserDetailsForm;

import React from 'react';
import { sendMail } from '../functions/usefulFunctions';
import Logo from './../resources/Images/eidverseLogo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-black text-[#7C7C7C]">
      <div className="flex flex-col inter-font pt-[64px] sm:pb-[25px] max-sm:pb-[50px]  px-[32px] sm:max-w-[752px] mx-auto">
        <div className="">
          <div className="max-sm:text-[24px] sm:text-[26px] font-semibold sm:mb-[30px]">
            <h3 className=" leading-[29px] text-white mb-[2px]">
              Thank you for
            </h3>
            <h1 className=" text-[#e9d66b]">your interest</h1>
          </div>
        </div>
        <div className="sm:grid grid-flow-col sm:mb-[24px]">
          <div className="max-sm:text-[13px] sm:text-[12px] mt-[24px]">
            <p className="text-[#e9d66b]">JOIN US</p>
            <div className="max-sm:text-[15px] sm:text-[14px] text-[#FFF8E7] mt-2">
              {/* <a
                href="https://calendly.com/evqlabs/the-eidverse"
                className="underline hover:opacity-75"
              >
                Schedule A Call
              </a>
              <br /> */}
              <a
                href="https://award.eidverse.io/"
                className="underline hover:opacity-75"
              >
                Become an Owner
              </a>
              <br />
              <div onClick={sendMail} className="underline hover:opacity-75">
                community@evqlabs.com
              </div>
              <br />
            </div>
          </div>
          <div className="max-sm:text-[13px] sm:text-[12px] sm:mt-[24px]">
            <p className=" text-[#e9d66b]">FIND OUT MORE</p>
            <div className="max-sm:text-[15px] sm:text-[14px] text-[#FFF8E7] mt-2">
              <a href="/our-team" className="underline hover:opacity-75">
                Our Team
              </a>
              <br />
              <a href="/terms" className="underline hover:opacity-75">
                Terms of Service
              </a>
              <br />
              <a href="/privacy" className="underline hover:opacity-75">
                Privacy Policy
              </a>
              <br />
            </div>
          </div>
          <div className="max-sm:text-[13px] sm:text-[12px] mt-[24px]">
            <p className="text-[#e9d66b]">FOLLOW US</p>
            <div className="max-sm:text-[15px] sm:text-[14px] text-[#FFF8E7] mt-2">
              <a
                href="https://twitter.com/EidverseFC"
                className="underline hover:opacity-75"
              >
                X (Twitter)
              </a>
              <br />
              <a
                href="https://discord.gg/DZbCNSMp6C"
                className="underline hover:opacity-75"
              >
                Discord
              </a>
              <br />
            </div>
          </div>
        </div>
        <div className="max-sm:text-[12px] sm:text-[12px] text-[#7C7C7C] max-sm:mt-[36px] sm:mt-0">
          EID tokens are not intended to be available to people or companies who
          are residents of, or are located, incorporated or have a registered
          agent in, the United States or other restricted territories. More
          details can be found in our{' '}
          <a
            href="/terms"
            className="underline hover:opacity-75 cursor-pointer"
          >
            Terms of Use
          </a>
          .
        </div>
        <div className="max-sm:text-[12px] sm:text-[12px] text-[#7C7C7C] max-sm:mt-[24px] sm:mt-[24px]">
          All Rights Reserved ©️ {currentYear} EVQ Labs, Inc.
        </div>
        {/* <div className="mt-[100px] flex items-center">
        <img src={Logo} width="40px" height="38px" />
        <p className="text-[15px] pl-2">Eidverse F.C.</p>
      </div> */}
      </div>
    </div>
  );
};

export default Footer;

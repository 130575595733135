import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import FoundingTeamLetter from '../components/Team/FoundingTeamLetter';

const OurLetter = () => {
  return (
    <div>
      <Navbar />
      <FoundingTeamLetter />
      <Footer />
    </div>
  );
};

export default OurLetter;

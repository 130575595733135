import React from 'react';
import Navbar from '../components/Navbar';
import RoadmapContent from '../components/Roadmap/RoadmapContent';
import RoadmapHero from '../components/Roadmap/RoadmapHero';
import RoadmapIndex from '../components/Roadmap/RoadmapIndex';

const Roadmap = () => {
  return (
    <div>
      <Navbar />
      <RoadmapHero />
      <RoadmapContent />
      {/* <RoadmapIndex /> */}
    </div>
  );
};

export default Roadmap;

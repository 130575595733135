import React, { useEffect } from 'react';

const SubstackWidget = () => {
  useEffect(() => {
    const handleSubscriptionResult = (result) => {
      const messageElement = document.getElementById('custom-substack-message');

      if (result.success) {
        messageElement.textContent = 'Custom success message';
        messageElement.style.color = '#fff';
        messageElement.classList.remove('error');
        messageElement.classList.add('success');
      } else {
        messageElement.textContent = 'Custom error message';
        messageElement.style.color = '#fff';
        messageElement.classList.remove('success');
        messageElement.classList.add('error');
      }
    };

    window.CustomSubstackWidget = {
      substackUrl: 'eidverse.substack.com',
      placeholder: 'Enter your email here',
      buttonText: 'Subscribe',
      theme: 'custom',
      colors: {
        primary: '#000',
        input: '#fff',
        email: '#000',
        text: '#fff',
      },
      callback: handleSubscriptionResult,
    };

    const script = document.createElement('script');
    script.src = 'https://substackapi.com/widget.js';
    script.async = true;

    document.body.appendChild(script);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.id === 'custom-substack-embed') {
          const messageElement = mutation.target.querySelector('p.success');
          if (messageElement) {
            messageElement.textContent =
              'Thank you! Please check this email account to confirm your subscription.';
            messageElement.style.color = '#4267B2';
            messageElement.style.fontWeight = '500';
          }
        }
      });
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });

    return () => {
      document.body.removeChild(script);
      observer.disconnect();
    };
  }, []);

  return (
    <div
      id="custom-substack-embed"
      style={{ textAlign: 'center', width: '100%' }}
    />
  );
};

export default SubstackWidget;

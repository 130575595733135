import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Manifesto from '../components/Manifesto';
import JoinUsHome from '../components/JoinUsHome';
import Podcasts from './Podcasts';

function LandingPage() {
  return (
    <div className="Inter overflow-x-hidden">
      <Navbar />
      <Hero />
      {/* <Manifesto /> */}
      {/* <JoinUsHome /> */}
      {/* <Podcasts /> */}
      <Footer />
    </div>
  );
}

export default LandingPage;

import RoadmapData from '../../utils/RoadmapData';

const RoadmapIndex = ({
  data,
  handleSubtitleClick,
  activeTitle,
  activeSubtitle,
  handleSectionClick,
}) => {
  return (
    <div className=" w-[360px] pl-[30px] pr-6 h-screen overflow-y-auto fixed left-0 pb-28">
      <div className=" mt-[36px]">
        {RoadmapData.map((section, index) => (
          <div key={index} className="mb-4">
            <div
              className={`cursor-pointer text-lg py-2 ${
                activeTitle === index
                  ? 'font-semibold text-[#454545] text-[18px]'
                  : 'text-[#8F949B]'
              }`}
              onClick={() => handleSectionClick(index)}
            >
              {section.title}
            </div>
            {section.sections.map((section, subIndex) => (
              <div
                key={subIndex}
                className={`cursor-pointer pl-4 py-1 ${
                  activeTitle === index && activeSubtitle === subIndex
                    ? 'font-semibold text-[#454545] text-[16px]'
                    : 'text-[#8F949B]'
                }`}
                onClick={() => handleSubtitleClick(index, subIndex)}
              >
                {section.subtitle}
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* <style>
        {`
          ::-webkit-scrollbar {
            width: 3px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #8d8c8e;
            border-radius: 3px;
          }
        `}
      </style> */}
    </div>
  );
};

export default RoadmapIndex;

import React from 'react';
import cover from './../../resources/Images/letter-cover.png';

const FoundingTeamLetter = () => {
  return (
    <div className="max-w-[704px] mx-auto max-sm:px-[32px] sm:px-[10px] pt-[50px] pb-[120px] flex flex-col">
      <div>
        <h1 className="max-sm:text-[26px] tracking-wider uppercase sm:text-[32px] my-[16px] pb-3 font-bold">
          <span className="sm:hidden">
            Letter from <br /> the Founding Team
          </span>
          <span className="max-sm:hidden">Letter from the Founding Team</span>
        </h1>
        {/* <div className="max-sm:mb-8 max-sm:mt-[1rem] sm:mb-12 sm:mt-[2.5rem] flex justify-center">
          <img
            src={cover}
            width="100%"
            className="max-sm:w-[70%] sm:w-[50%]"
            alt=""
          />
        </div> */}
        <div className="max-sm:text-[18px] sm:text-[22px] mb-[28px]">
          <p>
            Inspired by the Islamic Golden Age, we founded the Eidverse. Our
            goal? To reimagine that era's spirit of community, innovation, and
            advancement using modern technologies.
            <br />
            <br />
            Recognizing the billions who trace their roots to this rich
            heritage, we are excited by the idea of coming together to ignite a
            new era of big, positive, enduring outcomes.
            <br />
            <br />
            At the heart of the Eidverse is 'shared ownership'. Every member is
            an owner, vested in our collective mission. EID tokens, created
            using open and shared technology, symbolize this ownership.
            <br />
            <br />
            Through awarding EID, the community actively shares and
            redistributes ownership, ensuring it's in the hands of those pushing
            our mission forward. Soon, we'll introduce pooling. This simple yet
            powerful approach will allow owners to come together, supporting
            promising initiatives and amplifying our collective impact.
            <br />
            <br />
            Your interest means a lot to us. We're looking for individuals ready
            to take ownership of the future. We invite you to join us.
            <br />
            <br />
            Warmest regards, <br />
            Eidverse Founding Team
          </p>
        </div>
      </div>

      {/* <form>
        <div className="mt-[30px]">
          <div className="sm:flex justify-center items-center">
            <input
              className="h-auto max-sm:w-[100%] outline-none text-[16px] leading-[22px] px-[12px] py-[16px] text-[#2F2440] border border-solid border-[#E2E0E0] max-sm:mb-[12px]"
              type="email"
              placeholder="Enter your email here"
              required
            />
            <button
              onClick={() => window.open('https://eidverse.substack.com')}
              type="submit"
              style={{ backgroundColor: 'black' }}
              className="h-auto max-sm:w-[100%] text-white uppercase sm:max-w-[460px]  text-[18px] font-bold leading-[22px] px-[12px] py-[16px]"
            >
              Subscribe
            </button>
          </div>
        </div>
      </form> */}
    </div>
  );
};

export default FoundingTeamLetter;

import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import telescope from '../resources/Images/telescope.png';
import SubstackWidget from './SubstackWidget';

const Hero = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      Emails: email,
    };
    axios
      .post(
        'https://sheet.best/api/sheets/ae3cc2d8-116f-4da3-ae79-b264155df440',
        data,
      )
      .then((response) => {
        console.log(response, 'store hoise naki dekh');
        if (response.status === 200) {
          toast.success('Successfully added to the list');
        }
        setEmail('');
      });
  };

  return (
    <div className="max-sm:w-[100vw] overflow-hidden flex flex-col justify-center px-[32px]">
      <div className="sm:max-w-[680px] sm:mx-auto mb-[135px]">
        <div className="mb-[30px] mt-[64px] max-sm:w-[100px] sm:w-[130px] max-sm:h-[100px] sm:h-[130px]">
          <img src={telescope} width="100%" height="100%" alt="" />
        </div>
        <h3 className="max-sm:text-[27px] sm:text-[39px] sm:mb-3.5 max-sm:mb-3 font-bold space-mono">
          <span className="sm:hidden">
            INTRODUCING THE <br /> EID PROTOCOL
          </span>
          <span className="max-sm:hidden">INTRODUCING THE EID PROTOCOL</span>
        </h3>
        <h1 className="text-[#2a2a2a] max-sm:text-[18px] sm:text-[30px] font-semibold sm:mb-[30px] max-sm:mb-[24px] sm:leading-[40px] max-sm:leading-[29px]">
          Enabling A New Paradigm for Impact and Ownership Aimed at Billions
        </h1>
        <div className="max-sm:text-[16px] leading-[29px] sm:text-[20px] sm:mb-[24px] max-sm:mb-[40px] tracking-[0.4px] text-[#383838]">
          We believe the support and funding mechanisms that fueled human
          progress during the Islamic Golden Age can be democratized and
          turbocharged using modern, open technologies.{' '}
          <a
            href="/overview"
            className="text-[#4267B2] hover:opacity-75 underline"
          >
            Learn how
          </a>
        </div>
        <div
          style={{
            fontFamily:
              'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          }}
          className="pt-[70px] flex flex-col items-center"
        >
          <p className="mb-3  text-[19.5px] text-center font-medium tracking-wider">
            <a
              href="https://eidverse.substack.com"
              target="_blank"
              className="text-black hover:opacity-75 font-semibold"
            >
              Subscribe to <br />{' '}
              <span className="text-[#555555]">The Founding Community</span>
            </a>
          </p>
          <SubstackWidget />
          <p className="mt-3 text-[14px] italic text-[#555555] text-center">
            Sign up to stay connected and informed about the EID ecosystem,
            including ownership opportunities
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React, { useState } from 'react';
import { FaDiscord, FaInstagram, FaTwitter } from 'react-icons/fa';
import Logo from './../resources/Images/eidverseLogo.png';
import { GiHamburgerMenu } from 'react-icons/gi';

const Clone = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="leading-[25px] text-[16px] flex items-center justify-between relative bg-[#1E2640]">
        <div className="pl-4 py-4">
          <img src={Logo} width="40px" height="40px" />
        </div>
        <div onClick={toggleDrawer} className="p-4 cursor-pointer">
          <span className="text-white text-2xl">
            <GiHamburgerMenu />
          </span>
        </div>
        <div
          className={`flex flex-col pl-5 bg-[#DC9750] absolute top-full left-0 right-0 overflow-hidden transition-all duration-300 text-black ${
            isOpen ? 'h-80' : 'h-0'
          }`}
        >
          <a href="/manifesto" className="py-4 ">
            Manifesto
          </a>
          <a href="" className="py-4">
            Newsletter
          </a>
          <a href="" className="py-4">
            Twitter
          </a>
          <div className="flex justify-center text-[24px] absolute bottom-3 left-0 right-0 px-auto">
            <p className="px-4">
              <FaTwitter />
            </p>
            <p className="px-4">
              <FaInstagram />
            </p>
            <p className="px-4">
              <FaDiscord />
            </p>
          </div>
        </div>
      </div>
      <div className="h-[100vh] max-sm:w-[100vw] overflow-hidden text-center bg-[#1E2640] text-[#F3EAC0] flex flex-col justify-center px-[32px]">
        <div className="sm:max-w-[704px] sm:mx-auto">
          <h1 className="uppercase text-[64px] max-sm:text-[28px] font-bold mb-[12px] font-['Poppins'] sm:leading-[67px] max-sm:leading-[29px]">
            Support, Fund and Build The Future
          </h1>
          <p className="max-sm:text-[16px] text-[24px] mb-[64px] tracking-[0.4px]">
            You own everything do in the Eidverse - and you own it collectively.
          </p>
          <div className="uppercase text-[16px] max-sm:text-[14px] tracking-[2.8px] mb-[12px]">
            Subscribe to our Substack
          </div>
          <div className="mx-[12px] sm:flex justify-center gap-x-2">
            <input
              type="email"
              className="h-auto max-sm:w-[100%] outline-none text-[16px] leading-[22px] px-[12px] py-[12px] text-black border border-solid border-[#d5bb6f] mb-[12px]"
              maxLength="256"
              name="email"
              data-name="Email"
              placeholder="Email address"
              id="email"
              required
            />
            <button className="h-auto max-sm:w-[100%] mb-[12px] text-[16px] text-black font-bold leading-[22px] px-[12px] py-[12px] bg-[#DC9750]">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="px-[16px] sm:text-[44px] text-[21px] sm:leading-[60px] max-sm:leading-[29px] bg-[#F4EBD0] flex flex-col items-center justify-center py-[100px]">
        <p className="mb-[32px] max-w-[888px]">
          We are putting the future in the hands of those who have demonstrated
          the qualities to be leaders and pioneers.
        </p>
        <a
          href="/manifesto"
          className="bg-[#DC9750] px-[24px] py-[12px] text-[18px] leading-[25px] cursor-pointer inline-block font-bold"
        >
          Read our Manifesto
        </a>
      </div>
      <div className="px-[16px] py-[100px] bg-[#fffefb] flex flex-col items-center">
        <h1 className="max-sm:text-[28px] text-[64px] font-['Poppins'] font-bold mb-[12px] text-black max-w-[704px] sm:leading-[67px] max-sm:leading-[29px] uppercase text-center">
          Become an Afropolitan
        </h1>
        <p className="text-[18px] sm:text-[21px] max-w-[704px] leading-[25px] mb-[32px]">
          Afropolitan is looking to onboard the most dedicated and ambitious
          people to join our growing Network. We care less about what you’ve
          done and more about who you aspire to become and how we can all be
          better together.
        </p>
        <a
          href="/join"
          className="bg-[#DC9750] px-[24px] py-[12px] text-[18px] leading-[25px] cursor-pointer inline-block font-semibold"
        >
          Join Us
        </a>
      </div>
      <div className="py-[100px] px-[16px] bg-[#F4EBD0] flex flex-col items-center">
        <div className="flex flex-col items-center mb-[64px]">
          <h1 className="text-[64px] max-sm:text-[28px] font-bold text-[#000] uppercase text-center sm:leading-[67px] max-sm:leading-[29px] mb-[32px] font-['Poppins'] max-w-[704px]">
            Stories from Eidverse Owners
          </h1>
          <div
            className="max-w-[888px]"
            style={{
              border: '2px solid #cf574a',
              borderRadius: '22px',
              padding: '24px',
            }}
          >
            <h2 className="text-[#000] font-['Poppins'] uppercase mt-[8px] text-[22px] leading-[23px] mb-[12px] font-bold">
              Shaping The Future Of African Commerce Through Vertical
              Integration With Daniel Yu (Founder & CEO of Wasoko)
            </h2>
            <p className="text-[18px] italic text-[#726c69] leading-[25px]">
              May 17, 2023
            </p>
            <div
              className="text-[18px] leading-[25px]"
              style={{ display: 'none' }}
            >
              j
            </div>
            <p className="text-[18px] italic text-[#726c69] leading-[25px]">
              27 min
            </p>
            <p className=" mt-[8px] text-[18px] leading-[25px]">
              "The spirit of Afropolitan, and why I'm so excited about the
              community that's being built, is to connect one of the most...
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center py-[80px] bg-[#fcf9ef]">
        <div className="flex text-[24px] mb-4">
          <a href="https://twitter.com/EidverseFC" className="px-4">
            <FaTwitter />
          </a>
          <p className="px-4">
            <FaInstagram />
          </p>
          <p className="px-4">
            <FaDiscord />
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="py-2">Manifesto</p>
          <p className="py-2">Newsletter</p>
          <p className="py-2">Twitter</p>
        </div>
        <div className="mt-3 text-[12px] text-[#726c69]">
          <p className="py-2">Terms of Service</p>
          <p className="py-2">Privacy Policy</p>
          <p className="py-2">Built by Eidverse FC</p>
        </div>
      </div>
    </div>
  );
};

export default Clone;

import React from 'react';

const RoadmapHero = () => {
  return (
    <div className="font-bold text-[#2F2440] flex flex-col">
      <div
        className="py-20"
        style={{ backgroundImage: 'linear-gradient(#ffd282,#fcf9ef)' }}
      >
        <h1 className="uppercase text-[28px] leading-[29px] mb-[32px] mx-[16px] text-center">
          The Eidverse Roadmap
        </h1>
      </div>
    </div>
  );
};

export default RoadmapHero;

import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function Privacy() {
  return (
    <div>
      <Navbar />
      <div className="grid grid-rows-['1fr/auto'] min-h-screen sm:leading-[29px]">
        <div className="grid content-center pb-16 lg:pb-24 selection:text-[#ffffff] selection:bg-[#4940e0] px-5">
          <div
            style={{
              paddingLeft: 'calc((100% - 816px) / 2)',
              paddingRight: 'calc((100% - 816px) / 2)',
            }}
            className="grid gap-y-10 px-4"
          >
            <h1 className="sm:text-[32px] max-sm:text-[24px] mt-[50px] uppercase font-bold">
              Privacy Policy
            </h1>
            <div className="grid gap-y-2 justify-items-start max-sm:text-[16px] sm:text-[18px]">
              <p className="">Last Updated: June 1st, 2024</p>
              <p className="mt-4">
                Thank you for being a part of the Eidverse Founding Community!
                At Eidverse, we are a global community of developers,
                professionals, and contributors dedicated to supporting and
                building the future together. This Privacy Notice is here to
                help you understand how we collect and use your information when
                you visit our website (
                <a
                  className="hover:opacity-50 transition-opacity leading-7 lg:leading-none font-bold text-[#000000]"
                  href="/"
                  target="_blank"
                >
                  eidverse.io
                </a>
                ), use our applications (“App”, “EID App”) (
                <a
                  className="hover:opacity-50 transition-opacity leading-7 lg:leading-none font-bold text-[#000000]"
                  href="https://award.eidverse.io/"
                  target="_blank"
                >
                  award.eidverse.io
                </a>
                ), or engage with our other services (collectively, “The
                Services”).
              </p>
              <p className="mt-4">
                Your privacy is our priority, and we are committed to handling
                your data with the utmost care and responsibility. By being part
                of our community, you can trust that we are dedicated to
                safeguarding your information. This Privacy Notice is
                incorporated into and governed by the User Terms and Conditions
                at{' '}
                <a
                  className="hover:opacity-50 transition-opacity leading-7 lg:leading-none font-bold text-[#000000]"
                  href="/terms"
                >
                  eidverse.io/terms
                </a>{' '}
                ("User Terms").
              </p>
              <h2 className="font-bold sm:text-[20px] mt-4">1. Controllers</h2>
              <p className="ml-6 mb-3 -mt-2">
                EVQ Labs ("EVQ Labs," "we," "our," or "us") acts as the data
                controller. You can contact us at{' '}
                <a
                  className="hover:opacity-50 transition-opacity
                  font-bold text-[#000000]"
                  href="mailto:community@evqlabs.com"
                >
                  {' '}
                  community@evqlabs.com
                </a>{' '}
                for any privacy-related questions or concerns. Our
                responsibilities include the development, management, and
                support of our "Services," ensuring your requests are handled
                promptly and in accordance with statutory obligations.
                Additionally, EVQ Labs handles daily business routines,
                including staff management and recruitment, to maintain seamless
                and lawful operations.
              </p>
              <h2 className="font-bold sm:text-[20px] mt-4">
                2. Updates to this Privacy Notice
              </h2>
              <p className="ml-6 mb-3 -mt-2">
                We update this Privacy Notice sometimes. If we make major
                changes, such as how we use your personal information, then
                we'll let you know via an email or a message in your App.
              </p>
              <h2 className="font-bold sm:text-[20px] mt-4">
                3. What is in this Privacy Notice?
              </h2>
              <ul className="list-disc list-inside ml-6 mb-3 -mt-2">
                <li>Our commitment to protecting your privacy and data</li>
                <li>Information we collect and why</li>
                <li>How we use the data we collect</li>
                <li>Where we process your data</li>
                <li>When we share your data</li>
                <li>How your data is recorded on the public blockchain</li>
                <li>How we use cookies</li>
                <li>How long we keep your data</li>
                <li>How this Privacy Notice differs for children and teens</li>
                <li>The statutory rights you have under GDPR</li>
                <li>How to contact us about this Privacy Notice</li>
              </ul>
              <h2 className="font-bold sm:text-[20px] mt-4">
                4. Our Commitment to Protecting Your Privacy and Data
              </h2>
              <p className="ml-6 mb-3 -mt-2">
                Protecting your privacy and securing your data is at the heart
                of everything we do. We understand that earning your trust is
                crucial to our mission of distributing digital tokens fairly to
                as many people as possible. EVQ Labs upholds your rights to
                access, correct, transfer, restrict, and delete your personal
                information, regardless of who you are.
              </p>
              <div className="ml-6 mb-3">
                <h2 className="font-bold sm:text-[20px] mt-4">Privacy</h2>
                <p className=" mb-4">
                  We have designed our products and services with your privacy
                  in mind. We collect data to improve our product and services.
                  We will always tell you, here in this Privacy Notice or in
                  data consent forms for specific products or services, what
                  data we are collecting, why we are collecting that data, and
                  what we do with it.
                </p>
                <h2 className="font-bold sm:text-[20px] mt-4">Data Security</h2>
                <p>
                  We have a dedicated team to look after your data and have
                  implemented physical and electronic safeguards that protect
                  your data both in transit and at rest. At the same time, no
                  service can be completely secure. If you have any concerns
                  about your account or your data, please contact us at{' '}
                  <a
                    className="hover:opacity-50 transition-opacity
                  font-bold text-[#000000]"
                    href="mailto:community@evqlabs.com"
                  >
                    {' '}
                    community@evqlabs.com
                  </a>
                </p>
              </div>
              <h2 className="font-bold sm:text-[20px] mt-4">
                5. Information We Collect and Why
              </h2>
              <h3 className="font-bold sm:text-[20px] mt-4 text-md ml-6">
                5.1 Data You Provide To Us
              </h3>
              <p className="ml-6 -mt-2">
                As a user, you are required to provide some information to
                access the EID App. The legal grounds for processing in the
                cases below are the user's consent and the performance of a
                contract (our commitment to provide the Services). Below is a
                list of data that you may provide and what we may use the data
                for:
              </p>
              <ul className="list-disc list-inside ml-8 my-2">
                <li>
                  <span className="font-bold">Email address:</span> You are
                  required to connect your Google Account to access the EID App.
                  You may also provide your email to subscribe to our mailing
                  list to stay up to date with the Eidverse project. We may
                  require your email when you submit a data subject request. The
                  legal basis for processing this data is the performance of the
                  Service under the User Terms.
                </li>
                <li>
                  <span className="font-bold">First and last name:</span> EVQ
                  Labs and other users may see your name when they choose to
                  interact with your account. We may require your name when you
                  submit a data subject request. The legal basis for processing
                  this data is the performance of the Service under the User
                  Terms.
                </li>
                <li>
                  <span className="font-bold">
                    Feedback and correspondence from you:
                  </span>{' '}
                  These include any emails, chat messages, or other
                  communications that you send us via email or third-party
                  social media websites. We may use a third-party service
                  provider to facilitate surveys about your use of our Services.
                  The legal basis for processing this data is the performance of
                  the Service under the User Terms.
                </li>
              </ul>
              <h3 className="font-bold sm:text-[20px] mt-4 text-md ml-6">
                5.2 Data We Collect From Third-Party Sources
              </h3>
              <div className="ml-6 -mt-2">
                <p>
                  From time to time, we may obtain information about you from
                  the following third-party sources:
                </p>
                <ul className="list-disc list-inside ml-2 my-2">
                  <li>
                    <span className="font-bold">Blockchain Data:</span> We may
                    analyze public blockchain data to ensure parties utilizing
                    our Services are not engaged in illegal or prohibited
                    activity under the User Terms, and to analyze transaction
                    trends for research and development purposes. The legal
                    basis for processing this data is compliance with legal
                    obligations.
                  </li>
                  <li>
                    <span className="font-bold">
                      Identity Verification Services:
                    </span>{' '}
                    We may obtain information from third-party services using
                    your data to verify your identity if required by law (such
                    as applicable know-your-customer requirements). To clarify,
                    we do not use your biometric data when we verify your
                    identity as required by law. The legal basis for processing
                    this data is compliance with legal obligations.
                  </li>
                  <li>
                    <span className="font-bold">Talent databases:</span> We may
                    collect data from various sources to make job offers to
                    talented individuals. The legal basis for processing this
                    data is legitimate interests. The legitimate interests
                    pursued are our interest to recruit individuals and the
                    individual's interest to receive job offers that entail an
                    exciting mission and high compensation.
                  </li>
                </ul>
              </div>
              <h3 className="font-bold sm:text-[20px] mt-4 text-md ml-6">
                5.3 Data We Collect Automatically
              </h3>
              <div className="ml-6 -mt-2">
                <p>
                  If permitted under applicable law, we may collect certain
                  types of data automatically when you interact with our
                  Services. This information helps us address customer support
                  issues, improve the performance of the Services, provide you
                  with a streamlined and personalized experience, and secure
                  your Account credentials. Information collected automatically
                  includes:
                </p>
                <ul className="list-disc list-inside ml-2 my-2">
                  <li>
                    <span className="font-bold">Online Identifiers:</span>{' '}
                    Geo-location and tracking details, computer or mobile phone
                    operating system, web browser name and version, and IP
                    addresses. The legal basis for processing this data is
                    compliance with legal obligations (in very limited cases as
                    these data are also fed into our fraud and illicit financial
                    flow detection) and performance of the contract as we wish
                    to provide a stable and fraud-free experience of our
                    software.
                  </li>
                  <li>
                    <span className="font-bold">Usage Data:</span>{' '}
                    Authentication data, security questions, and other data
                    collected via cookies and similar technologies. The legal
                    basis for processing this data is the performance of our
                    service under the User Terms.
                  </li>
                  <li>
                    <span className="font-bold">Cookies:</span> small data files
                    stored on your hard drive or in-device memory that help us
                    improve our Services and your experience, see which areas
                    and features of our Services are popular, and count visits.
                    For the legal basis processing those data please refer to
                    our Cookie Policy where we explain the different kinds of
                    cookies we are using.
                  </li>
                </ul>
                <p>
                  Similarly, the App gathers information for troubleshooting and
                  improvement. We use third-party services to view aggregated
                  information about end-user usage and interactions. Where
                  possible, we take steps to minimize or mask the information
                  sent to third parties (such as encoding the data). The legal
                  basis for processing the above-mentioned data is the
                  legitimate interest of operating the App and the website.
                </p>
              </div>
              <h3 className="font-bold sm:text-[20px] mt-4 text-md ml-6 mt-2">
                5.4 Anonymized and Aggregated Data
              </h3>
              <div className="ml-6 -mt-2">
                <p>
                  Anonymization is a data processing technique that modifies
                  personal data so that it cannot be associated with a specific
                  individual. Examples of anonymized data include:
                </p>
                <ul className="list-disc list-inside ml-2 my-1">
                  <li>Transaction data</li>
                  <li>Click-stream data</li>
                  <li>Performance metrics</li>
                  <li>Fraud indicators</li>
                </ul>
                <p>
                  We also aggregate data, combining large amounts of information
                  together so that it no longer identifies or references an
                  individual. We use anonymized or aggregate data for our
                  business purposes, such as understanding user needs and
                  behaviors, improving our Services, conducting business
                  intelligence and marketing, detecting security threats, and
                  training our algorithms.
                </p>
                <p>
                  The legal basis for processing the above-mentioned data is the
                  legitimate interest of a functioning app or website, business
                  insights, and fraud prevention.
                </p>
              </div>
              <h2 className="font-bold sm:text-[20px] mt-4">
                6. How We Use the Data We Collect
              </h2>
              <div className="ml-6 -mt-2">
                <p>
                  We must have a valid reason (or "lawful basis for processing")
                  to use your personal information. In instances when you'd
                  reasonably expect us to use your personal information and our
                  use of that information complies with applicable laws, we
                  don't ask for your express permission. When it comes to your
                  biometric data, we ask for your explicit and informed consent.
                </p>
                <p className="mt-2">
                  We use your data for the following purposes:
                </p>
                <ul className="list-disc list-inside my-2">
                  <li>
                    To provide and maintain our products and services under the
                    User Terms. These services include:
                    <ol className="list-decimal list-inside text-[16px] ml-6 my-2">
                      <li>
                        The Eidverse ID – a Privacy Preserving Proof of
                        Personhood which is only granted to individual humans
                        once as a pair of a public and a private key. The
                        Eidverse ID can be used at the user's discretion to
                        prove their uniqueness in the context of other services;
                      </li>
                      <li>The airdrop of the digital tokens;</li>
                      <li>
                        The EID App where users can manage their Eidverse ID and
                        digital tokens as well as learn about cryptocurrency in
                        general and the Eidverse project in specific;
                      </li>
                      <li>
                        The legal basis for all these cases is the performance
                        of a contract, namely, the User Terms.
                      </li>
                    </ol>
                  </li>
                  <li>
                    To improve and develop our products and services, including
                    to debug and repair errors in our Services. The legal basis
                    for this processing is legitimate interests. The interests
                    pursued are providing a stable and safe experience with our
                    software and hardware.
                  </li>
                  <li>
                    To conduct data science research. The legal basis for this
                    processing is the legitimate interests of providing a better
                    user experience, greater support, and more useful features
                    in the Services.
                  </li>
                  <li>
                    To analyze your use of our Services to provide better
                    support. The legal basis for this processing is legitimate
                    interests. The interests pursued are providing a better
                    experience of our soft- and hardware;
                  </li>
                  <li>
                    To enable you to publish information on a blockchain to
                    prove your uniqueness. The legal basis for this processing
                    is your explicit consent;
                  </li>
                  <li>
                    To use your wallet address to send you digital tokens we
                    support. The legal basis for this processing is the
                    performance of a contract, namely, the User Terms.;
                  </li>
                  <li>
                    To comply with applicable law such as anti-money-laundering
                    law and sanctions. This entails:
                    <ol className="list-decimal list-inside ml-6 my-2">
                      <li>
                        Using your IP address to block individuals whose country
                        does not allow them to access the Services;
                      </li>
                      <li>
                        To answer data subject requests under the applicable
                        data protection laws like requests for access or
                        deletion;
                      </li>
                      <li>
                        Monitor potentially illicit financial flows e.g. from
                        blacklisted wallets; and
                      </li>
                      <li>
                        The legal basis for this processing data is compliance
                        with legal obligations.
                      </li>
                    </ol>
                  </li>
                  <li>
                    To handle your customer service requests, complaints, and
                    inquiries. The legal basis for this processing is the
                    performance of a contract, namely, the User Terms.
                  </li>
                  <li>
                    To resolve disputes, troubleshooting issues, and enforcing
                    our agreements with you, including this Privacy Notice and
                    the User Terms. The legal basis of this processing is the
                    performance of a contract, namely, the User Terms and
                    legitimate interests where the interests pursued are the
                    defense of legal claims; and
                  </li>
                  <li>
                    To contact you regarding updates to the Services. The legal
                    basis of this processing is the performance of a contract,
                    namely, the User Terms.
                  </li>
                </ul>
              </div>
              <h2 className="font-bold sm:text-[20px] mt-4">
                7. When We Share Your Data
              </h2>
              <div className="ml-6 -mt-2">
                <p>
                  We will never sell your data. When we share your data outside
                  of EVQ Labs, we will always:
                </p>
                <ul className="list-disc list-inside ml-3 my-2">
                  <li>Share it in a reasonably secure way;</li>
                  <li>
                    Take steps to ensure that it is handled in a manner that is
                    consistent with our commitment to your privacy; and
                  </li>
                  <li>
                    Prohibit other companies from using it for their own
                    purposes.
                  </li>
                </ul>
                <p>We do share your data in these limited ways:</p>
                <ul className="list-disc list-inside ml-3 my-2">
                  <li>
                    <b>With the Eidverse:</b> Data, including your personal
                    information, may be shared with the Eidverse Foundation or a
                    subsequent organization responsible for promoting and
                    furthering the mission of the Eidverse project.
                  </li>
                  <li>
                    <b>Within EVQ Labs:</b> We only disclose data to our team
                    members who require access in order to perform their tasks
                    and duties. We only disclose as much data as is needed to
                    perform specific tasks and duties and have a system of
                    strict access control.
                  </li>
                  <li>
                    <b>
                      With vendors and service providers outside of EVQ Labs:
                    </b>{' '}
                    We only disclose data to service providers whose services we
                    rely on in order to process the data and provide our
                    Services to you. We only disclose data with identity
                    verification vendors if required by law (i.e.,
                    know-your-customer requirements). The categories of such
                    service providers are specified in our records
                  </li>
                  <li>
                    <b>
                      With law enforcement, officials, or other third parties:
                    </b>{' '}
                    We may disclose your data in order to comply with applicable
                    laws and respond to mandatory legal demands. We will
                    carefully consider each request to determine whether the
                    request complies with the law and, where appropriate, we may
                    challenge invalid, overbroad, or unlawful requests. We may
                    share personal data with police and other government
                    authorities where we reasonably believe it to be necessary
                    to comply with law, regulation, or other legal process or
                    obligation.
                  </li>
                  <li>
                    We may share your personal information if we believe that
                    your actions are inconsistent with our User Terms, if we
                    believe that you have violated the law, or if we believe it
                    is necessary to protect the rights, property, and safety of
                    EVQ Labs, our users, the public, or others.
                  </li>
                  <li>
                    We may share your personal information with our lawyers and
                    other professional advisors where necessary to obtain advice
                    or otherwise protect and manage our business interests.
                  </li>
                  <li>
                    We may share your personal information in connection with,
                    or during negotiations concerning, any merger, sale of
                    company assets, financing, or acquisition of all or a
                    portion of our business by another company.
                  </li>
                  <li>
                    Data, including your personal information, may be shared
                    between and among our current and future parents,
                    affiliates, and subsidiaries and other companies under
                    common control and ownership.
                  </li>
                  <li>
                    We may share your personal information with your consent or
                    at your direction.
                  </li>
                </ul>
              </div>
              <h2 className="font-bold sm:text-[20px] mt-4">
                8. How Your Data is Recorded on Public Blockchain
              </h2>
              <p className="ml-6 -mt-2">
                Transaction information related to your use of our Services may
                be recorded on a public blockchain. Please note that blockchains
                are decentralized third-party networks that we do not control or
                operate. Due to the public and immutable nature of blockchain
                ledgers, we cannot guarantee the ability to amend, erase, or
                control the disclosure of data that is uploaded and stored on a
                blockchain.
              </p>
              <h2 className="font-bold sm:text-[20px] mt-4">
                9. How We Use Cookies
              </h2>
              <p className="ml-6 -mt-2">
                We use cookies to help our Services work better. In addition to
                cookies, we may use other similar technologies, like web
                beacons, to track users of our Services. To ensure the highest
                level of security for our users, we exclusively use HTTPOnly
                cookies. These cookies are not accessible through JavaScript or
                other client-side scripts, providing an extra layer of
                protection against potential security threats like cross-site
                scripting (XSS) attacks. Our Cookie Policy, incorporated herein
                by reference, provides detailed information on how we use
                cookies and similar technologies. By using the Services, you
                consent to EVQ Labs storing and accessing cookies and other data
                on your computer or mobile device and the use of third-party
                analytics services.
              </p>
              <h2 className="font-bold sm:text-[20px] mt-4">
                10. How Long Do We Keep Your Data?
              </h2>
              <p className="ml-6 -mt-2">
                We retain your data for as long as is reasonably necessary to
                provide our Services to you, serve our legitimate business
                purposes, and comply with our legal and regulatory obligations.
                If you close your account with us, we will delete your account
                data within 90 days; otherwise, we will delete your account data
                after 2 years of inactivity. If required by law, we will
                continue to retain your personal data as necessary to comply
                with our legal and regulatory obligations.
              </p>
              <p className="ml-6">
                <span className="font-bold">Please note:</span> Blockchains are
                decentralized third-party networks that we do not control or
                operate. Due to the public and immutable nature of blockchain
                technology, we cannot amend, erase, or control the disclosure of
                data that is stored on blockchains.
              </p>
              <h2 className="font-bold sm:text-[20px] mt-4">
                11. How this Privacy Notice Differs for Children and Teens
              </h2>
              <p className="ml-6 -mt-2">
                You may only access or use the Services if you are of legal age
                to form a binding contract. Individuals under the age of 18 are
                not allowed to use the Services, and we do not knowingly collect
                data from individuals under the age of 18. If you believe that
                your child under the age of 18 has gained access to the Services
                without your permission, you may request the deletion of their
                data by contacting us through our Request Portal at{' '}
                <a
                  className="hover:opacity-50 transition-opacity
                  font-bold text-[#000000]"
                  href="mailto:community@evqlabs.com"
                >
                  {' '}
                  community@evqlabs.com
                </a>
                .
              </p>
              <p className="ml-6 mb-2">
                If we learn that we have collected data about a child under age
                18, we will delete such data as quickly as possible. We have
                taken commercially reasonable steps to restrict use of the
                Services to those who are at least 18 years old. We do not
                market products or services for purchase by children. If you are
                a parent or legal guardian accepting these Terms for a minor,
                you confirm that you have the legal authority to do so, consent
                to their use of the Services, and agree to these Terms on both
                your and their behalf. You also agree to take responsibility for
                their use of the Services. In order to agree to the terms as a
                legal guardian, contact us at{' '}
                <a
                  className="hover:opacity-50 transition-opacity
                  font-bold text-[#000000]"
                  href="mailto:community@evqlabs.com"
                >
                  {' '}
                  community@evqlabs.com
                </a>{' '}
                for further information.
              </p>
              <h2 className="font-bold sm:text-[20px] mt-4">
                12. The statutory rights under GDPR
              </h2>
              <p className="ml-6 -mt-2">
                This section applies if the processing of your data falls under
                the GDPR's scope of application (e.g., if you are a resident of
                the European Economic Area). You may have additional rights
                under GDPR as listed below. To exercise your rights available
                under GDPR, please contact us at{' '}
                <a
                  className="hover:opacity-50 transition-opacity
                  font-bold text-[#000000]"
                  href="mailto:community@evqlabs.com"
                >
                  {' '}
                  community@evqlabs.com
                </a>
                .
              </p>
              <ul className="list-disc list-inside ml-8 mb-2">
                <li>
                  You have the right to obtain information about the personal
                  data we process concerning you within the scope of Art. 15
                  GDPR.
                </li>
                <li>
                  You have the right to demand that we correct the personal data
                  concerning you if it is incorrect.
                </li>
                <li>
                  You have the right to demand that we delete the personal data
                  concerning you under the conditions described in Art. 17 GDPR.
                </li>
                <li>
                  You have the right to demand that we restrict processing in
                  accordance with Art. 18 GDPR.
                </li>
                <li>
                  You have the right to receive from us the personal data
                  concerning you that you have provided to us in a structured,
                  commonly used, machine-readable format in accordance with Art.
                  20 GDPR.
                </li>
                <li>
                  You have the right to object to the processing of personal
                  data concerning you in certain circumstances in accordance
                  with Article 21 GDPR.
                </li>
                <li>
                  You have the right to contact the competent supervisory
                  authority in the event of complaints about the data processing
                  carried out by the controller.
                </li>
                <li>
                  If the processing of personal data is based on your consent,
                  you are entitled under Art. 7 GDPR to revoke your consent to
                  the use of your personal data at any time with effect for the
                  future.
                </li>
              </ul>
              <h2 className="font-bold sm:text-[20px] mt-4">
                13. Your Rights to Exercise
              </h2>
              <p className="ml-6 -mt-2">
                At EVQ Labs, we understand the importance of your privacy and
                the trust you place in us when sharing your data. We're
                committed to building a safe and responsible platform that
                respects your rights and protects your information. As a user of
                our services, you have the right to exercise control over your
                data. You can:
              </p>
              <ul className="list-disc list-inside ml-8 mb-2">
                <li>
                  Withdraw your consent at any time, should you choose to do so.
                </li>
                <li>
                  Object to the processing of your data if it's not based on
                  your consent.
                </li>
                <li>
                  Access your data and learn about how it's being processed.
                </li>
                <li>
                  Verify the accuracy of your data and ask for it to be updated
                  or corrected.
                </li>
                <li>
                  Restrict the processing of your data under certain
                  circumstances.
                </li>
                <li>Have your data deleted or removed if you so choose.</li>
                <li>
                  Receive your data in a structured format and have it
                  transferred to another controller if technically feasible.
                </li>
              </ul>
              <h2 className="font-bold sm:text-[20px] mt-4">
                14. How to Contact us About this Privacy Notice
              </h2>
              <p className="ml-6 -mt-2">
                If you have questions or concerns regarding this Privacy Notice,
                wish to exercise your rights please submit your request through
                our Request Portal at{' '}
                <a
                  className="hover:opacity-50 transition-opacity
                  font-bold text-[#000000]"
                  href="mailto:community@evqlabs.com"
                >
                  {' '}
                  community@evqlabs.com
                </a>
                . We respond to all requests we receive from individuals wishing
                to exercise their data protection rights in accordance with
                applicable data protection laws. If you have an unresolved
                privacy or data use concern that we have not satisfactorily
                addressed, please contact the data protection regulator in your
                jurisdiction.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Privacy;

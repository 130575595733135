import React, { useState } from 'react';
import BlackTopbar from '../components/BlackTopbar';
import Navbar from '../components/Navbar';
import RoadmapDetails from '../components/Roadmap/RoadmapDetails';
import RoadmapIndex from '../components/Roadmap/RoadmapIndex';
import RoadmapData from '../utils/RoadmapData';

const LearnMore = () => {
  const [activeTitle, setActiveTitle] = useState(-1);
  const [activeSubtitle, setActiveSubtitle] = useState(-1);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const handleSectionClick = (index) => {
    scrollToAnchor(`section-${index}`);
    setActiveTitle(index);
    setActiveSubtitle(-1);
  };

  const scrollToAnchor = (anchor) => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubtitleClick = (index, subIndex) => {
    setActiveTitle(index);
    setActiveSubtitle(subIndex);
  };

  return (
    <div className="flex flex-col h-screen">
      <BlackTopbar />
      <Navbar />
      <div className="overflow-y-auto overflow-x-hidden ">
        <div className="container md:grid grid-cols-4 mx-auto flex">
          <div className="col-span-1 max-md:hidden">
            <RoadmapIndex
              data={RoadmapData}
              handleSubtitleClick={handleSubtitleClick}
              activeTitle={activeTitle}
              activeSubtitle={activeSubtitle}
              handleSectionClick={handleSectionClick}
            />
          </div>
          <div className="md:col-span-3 leading-relaxed">
            <RoadmapDetails
              data={RoadmapData}
              activeTitle={activeTitle}
              activeSubtitle={activeSubtitle}
              setActiveTitle={setActiveTitle}
              setActiveSubtitle={setActiveSubtitle}
            />
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default LearnMore;

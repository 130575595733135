import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import coin from '../resources/Images/eidverse_logo.png';
import Footer from '../components/Footer';
import { CgFileDocument } from 'react-icons/cg';
import { HiPencilSquare } from 'react-icons/hi2';
import UserDetailsForm from '../components/Forms/UserDetailsForm';

const PledgeSupport = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div className="grid grid-rows-['1fr/auto'] min-h-screen">
        <div
          className="grid content-center pt-16 pb-16 lg:pb-48 selection:text-[#ffffff] selection:bg-[#003eb9]"
          style={{
            paddingLeft: 'calc((100% - 477px) / 2)',
            paddingRight: 'calc((100% - 477px) / 2)',
          }}
        >
          <div className="grid gap-y-6 mx-auto">
            <h1 className="sm:text-[45px] text-[32px] font-semibold sm:mt-[6.5rem] mt-[2rem] lg:mt-[18.72px] lg:mx-0 leading-none sm:w-[537px] w-[350px] max-sm:ml-5">
              Pledge Your Support
            </h1>
            <div className="grid gap-y-6 max-sm:ml-5 lg:text-[18px] tracking-normal font-normal text-[#454545]  sm:w-[500px] w-[350px]">
              <div>
                <p>
                  The Eidverse is a patronage and financial ecosystem. We aim to
                  support, fund, and build the future. A future that will be
                  filled with big, positive, and enduring outcomes.
                </p>{' '}
                <br />
                <p>
                  We're currently building a 'Patronage Network', the first
                  ingredient that fueled the Islamic Golden Age and other
                  Renaissance periods.
                </p>
                <br />
                <p>
                  It is a tried-and-true formula for substantial progress and
                  growth. Each of us will play a part in owning and shaping it.
                  It's all about reviving that successful recipe to inspire a
                  modern renaissance.
                </p>
                <br />
                <p>
                  Building on our 'Patronage Network', the Eidverse introduces
                  shared ownership, central to our ethos. The EID token
                  represents this ownership, giving everyone a stake in our
                  ecosystem. This approach empowers each contributor to actively
                  shape and own our shared future.
                </p>
                <br />
                <p>So, do you want to own a part of the future?</p>
              </div>
            </div>
            <div className="mt-5">
              <UserDetailsForm />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PledgeSupport;
